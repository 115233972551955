@import 'variables.scss';
.addPictureButtonStyle {
  height: 50px;
  width: 200px;
  border-radius: 27.5px;
  border: 1.5px dashed rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  background: $dark;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
  .imageOutlinedStyle {
    font-size: 21px;
    padding-left: 15px;
  }
}
.imageUploadContainer {
  flex: 0 0 auto;
  position: relative;
  :global {
    .lazy-load-image-background {
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
  .mainImageStyle {
    width: 100%;
    height: 600px;
    object-fit: cover;
    border-radius: 15px;
  }
  .rowImageStyle {
    height: 200px;
    width: 200px;
  }
  .loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 220px;
    .loaderImgStyle {
      width: 20%;
      object-fit: contain;
    }
  }
  .imageUploadButtonContainer {
    height: 70px;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, 0%);

    :global {
      .ant-upload {
        height: 50px;
        background: transparent;
        border: none;
        margin: 0;
        width: 100%;
      }
    }
  }
  .rowStyledButton {
    bottom: -38%;
  }
}
.customMargin {
  margin-right: 15px;
}
