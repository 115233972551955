@import 'variables.scss';
.businessPlatform {
  margin: 100px 0 127px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
  }

  .homeText {
    width: 80%;
    @media (max-width: 575px){
      text-align: start;
      width: 100%;
    }
  }

  @media (max-width: 575px) {
    margin: 50px 0;
  }
}
