@import 'variables.scss';
.editBarContainer {
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px 2px rgba(27, 27, 30, 0.2);
  background-color: #edeef1;
  border-radius: 10px;
  position: relative;
  @include sm {
    flex-direction: column-reverse;
    height: 140px;
    padding: 15px;
    align-items: flex-start;
  }
  .buttonSuccessStyle {
    height: 45px;
    line-height: 0;
    padding: 20px 20px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.28px;
    @include sm {
      width: 100%;
      line-height: normal;
    }
  }
  .imageContainer {
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
    box-shadow: 5px 5px 10px 0 #a6abbd;
    padding: 10px;
    cursor: pointer;
    img {
      width: 25px;
      height: 25px;
      object-fit: contain;
    }
  }
  .modeSelectButtonsContainer {
    display: flex;
    flex-direction: row;
    @include sm {
      width: 130px;
      justify-content: space-evenly;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-5%, 15px);
    }
  }
  .activeMode {
    box-shadow: none;
  }
  .checkOutlinedContainer {
    height: 45px;
    width: 163px;
    background: $success-color;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    .iconStyle {
      font-size: 23px;
      font-weight: bold;
      color: $white;
    }
    @include sm {
      margin-left: calc((100% - 163px)/2);
    }
  }
  .alignmentContainer {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    @include sm {
      width: 200px;
      justify-content: space-evenly;
      left: 0;
      top: 0;
      transform: translate(5%, 15px);
    }
  }
}
