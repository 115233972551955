@import 'variables.scss';
.mainContainer {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-top: 75px;
  margin-bottom: 3em;
  @include sm; @include tablets{
  margin-top: 30px;
  margin-bottom: 30px;
  }
  .searchTabContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2.5px solid $dark;
    width: 65%;
    @include sm {
      width: 100%;
    }
    .searchInputStyle {
      width: 95%;
      border: none;
      font-size: 26px;
      font-weight: 400;
      color: $dark;
      &:focus {
        outline: none;
        background: transparent;
      }
    }
    .searchOutlinedStyles {
      font-size: 28px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .countryDropdownContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    border-bottom: 1.5px solid black;
    @include sm {
      display: none;
    }
    h4 {
      font-size: 18px;
      font-weight: normal;
      margin-bottom: 0px;
    }
    :global {
      .ant-select-selector {
        border: none;
        .ant-select-selection-search {
          font-size: 18px;
          font-family: 'Poppins', sans-serif;
        }
      }
    }
  }
}

.banner {
  @media (min-width: 768px) {
    display: none;
  }
}
