@import 'variables.scss';
.mainSliderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include sm {
    min-width: 60%;
  }
  .fillContainer {
    height: 5px;
    width: 80%;
    background-color: #e0e0de;
    border-radius: 15px;
    @include sm {
      width: 85%;
    }
    .fillerContainer {
      height: 100%;
      background: $success-color;
      border-radius: inherit;
    }
  }
  .twoToneStarStyle {
    @include sm {
      display: none;
    }
  }
  .starFilledStyle {
    @include sm {
      font-size: 21px
    }
  }
}
