@import 'variables.scss';
.loginFormContainer{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 33%;
    padding-bottom: 15px;
    align-self: center;
  }
  h1 {
    font-size: 28px;
    line-height: 1.32;
    letter-spacing: 0.1px;
    color: $dark;
  }
  h2 {
    font-size: 16px;
    letter-spacing: 0.06px;
    color: $dark;
    padding-bottom: 40px;
  }
  h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.32px;
    color: $dark;
    padding-top: 20px;
    a {
      color: $dark;
      text-decoration: underline;
    }
  }
}
.loginHeader{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
