@import 'variables.scss';
.buttonSuccessStyle {
  padding: 20px;
  border: none;
  border-radius: 6px;
  line-height: 0;
  font-size: 12px;
  font-weight: 500;
  height: 45px;
  letter-spacing: 0.35px;
  color: $white;
  text-transform: uppercase;
  background: $dark;
  @include sm {
    width: 100%;
    border-radius: 6px;
    font-size: 13px;
    letter-spacing: 0.3px;
  }
  &:hover {
    background-color: $success-color;
    color: $dark;
  }
  &:focus {
    outline: none;
  }
}
