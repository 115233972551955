@import "variables.scss";

.createCommentContainer {
    .title {
      font-weight: 600;
      line-height: normal;
      margin-bottom: 0px;
    }
    .commentArea {
      max-width: 741px;
      width: 100%;
      height: 170px;
      border: solid 1px #c5d0d8;
      background-color: #fcfcfc;
      margin-top: 15px;
      margin-bottom: 21px;
      padding: 21px 28px;
      font-size: 17px;
      font-family: Arial;
      line-height: 1.82;
    }
  
    .commentAreaNotValid {
      outline: solid 1px rgba(255, 0, 0, 0.9);
    }
  
    .createCommentButton {
      height: 60px;
      padding: 18px 28.5px;
      width: fit-content;
      display: block;
      font-size: 16px;
      line-height: 16px;
    }
  }