@import 'variables.scss';
.buttonCancelStyle {
    padding: 20px 20px;
    border: solid 1.5px $dark;
    border-radius: 6px;
    font-size: 12px;
    height: 45px;
    line-height: 0;
    font-weight: 500;
    letter-spacing: 0.35px;
    color: $dark;
    text-transform: uppercase;
    background: $white;
    &:hover {
      opacity: 0.6;
    }
    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 0.5;
    }
    @include sm {
      width: 100%;
    }
   
        
  }