@import 'variables.scss';
.productsAndServicesHeaderContainer {
  font-family: 'Poppins', sans-serif;
  background: $dark;
  .productsAndServicesHeaderContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  h4 {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: $white;
    padding-top: 80px;
    margin-bottom: 10px;
  }
  .inputSectionTitle {
    width: 100%;
    background: transparent;
    color: $white;
    border-radius: 4px;
    margin-bottom: 47px;
    font-size: 30px;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
  }
  .inputSectionDescription {
    word-wrap: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens:auto;
    width: 100%;
    background: transparent;
    color: $white;
    border-radius: 4px;
    resize: none;
    font-size: 14px;
    line-height: 1.5;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 65px;
    @include sm {
      margin-bottom: 15px;
    }
  }
  .navigationRightContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 40px;
    cursor: pointer;
    @include sm {
      display: none;
    }
    h5 {
      color: $white;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.25;
      text-align: right;
      img {
        width: 15px;
        object-fit: contain;
      }
    }
    h3 {
      color: $white;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.17;
    }
  }
  .flexContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .sectionTextViewMode {
    border: none;
    padding: 5px 0px;
    font-weight: 400;
  }
  .descriptionSectionContainer {
    width: 35%;
    @include sm {
      width: 100%;
      margin: auto;
    }
  }
  .textCounterContainer {
    width: 100%;
    margin-bottom: 0px;
    text-align: right;
    font-size: 12px;
    color: $white;
  }
}
