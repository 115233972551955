@import 'variables.scss';
.createProductsContainer {
  font-family: 'Poppins', sans-serif;
  @include sm {
    padding-bottom: 40px;
  }
  .navigationInfo {
    display: flex;
    flex-direction: row;
    font-size: 15.7px;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 60px;
    padding-left: 15px;
    @include sm {
      display: none;
    }
    h5 {
      color: $dark;
      text-transform: uppercase;
      &:hover {
        filter: invert(50%);
      }
      cursor: pointer;
    }
    h6 {
      line-height: inherit;
      color: $dark;
    }
  }
  .mobileNavigationInfo {
    display: none;
    @include sm {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px 20px 15px;
      h6 {
        font-size: 15.7px;
        font-weight: 600;
      }
      .iconStyle {
        font-size: 20px;
        color: $steel-42;
      }
    }
  }
  .createProductContent {
    display: flex;
    flex-direction: row;
    @include sm {
      flex-direction: column-reverse;
    }
    .secondaryImagesUploadContainer {
      display: flex;
      align-items: flex-start;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-top: 26px;
      min-height: 340px;
    }
  }
  .textareaStyle {
    height: auto;
    textarea {
      width: 100%;
      resize: none;
    }
    .error {
      top: -20px;
    }
  }
  .formContainer {
    h5 {
      font-size: 12px;
      font-weight: normal;
      @include sm {
        margin-bottom: 45px;
      }
    }
    h4 {
      font-size: 20px;
      font-weight: 600;
      @include sm {
        display: none;
      }
    }
    .priceContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .currencyContianer {
        max-width: 45%;
        height: auto;
        display: flex;
        align-items: center;
        :global {
          .ant-select {
            width: 100%;
            padding: 0px 5px;
            &:focus {
              outline: none;
              border: none;
            }
            .ant-select-selector {
              border: none;
              background: transparent;
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              padding: 0px;
              box-shadow: none;
              .ant-select-selection-search {
                left: 0;
                color: $dark;
              }
              .ant-select-selection-placeholder {
                color: $dark;
                opacity: 0.6;
              }
            }
            &:focus {
              outline: none;
              border: none;
            }
          }
        }
        .currencyStyle {
          font-size: 16px;
          font-weight: normal;
          margin: 0px;
        }
      }
      .priceInputContainer {
        width: 50%;
      }
    }
    .datePickerStyle {
      display: flex;
      align-items: center;
      :global {
        input {
          border: none;
          background: transparent;
          color: $dark;
          &:focus {
            outline: none;
          }
        }
        .ant-picker {
          width: 100%;
          border: none;
          background: transparent;
          padding: 0px;
          .ant-picker-clear {
            transition: none;
          }
        }
        .ant-picker-focused {
          border: none;
          box-shadow: none;
          outline: none;
          .ant-picker-input {
            &:hover {
              .ant-picker-suffix {
                opacity: 0;
              }
              .ant-picker-clear {
                background: transparent;
                transition: none;
              }
            }
          }
        }
      }
    }
  }
  .tagContainer {
    padding-left: 15px;
    h3 {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 53px;
      margin-top: 100px;
      @include sm {
        margin-top: 20px;
      }
    }
    :global {
      .ant-select {
        width: 100%;
        padding: 0px 5px;
        &:focus {
          outline: none;
          border: none;
        }
        .ant-select-selector {
          border: none;
          background: transparent;
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          padding: 0px;
          box-shadow: none;
          .ant-select-selection-search {
            left: 0;
            color: $dark;
          }
          .ant-select-selection-placeholder {
            color: $dark;
            opacity: 0.6;
          }
        }
        &:focus {
          outline: none;
          border: none;
        }
      }
    }
    .addTagButtonStyle {
      text-transform: uppercase;
      border: 1.5px solid $dark;
      font-size: 12px;
      font-weight: bold;
      border-radius: 5px;
      padding: 3px 8px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-10px, -50%);
    }
    .tagsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 65px;
      @include sm {
        margin-bottom: 15px;
      }
      .tagWrapper {
        display: flex;
        align-items: center;
        height: 50px;
        border-radius: 25px;
        border: solid 0.5px #9da3b4;
        margin-right: 8px;
        margin-bottom: 15px;
        cursor: pointer;
        padding: 0px 25px;
        &:hover {
          background-color: $success-color;
        }
        p {
          font-size: 17px;
          font-weight: 500;
          margin: 0px;
          padding-right: 15px;
        }
      }
    }
  }
  .mainButtonContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 33px;
    align-items: center;
    .rubbishIcon {
      border-radius: 50%;
      box-shadow: 5px 5px 10px 0 #a6abbd;
      padding: 10px;
      border: none;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      img {
        width: 25px;
        height: 25px;
        object-fit: contain;
      }
    }
    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      width: 80%;
      .buttonSuccessStyle {
        height: 50px;
        padding: 0px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;
        @include sm {
          width: 55%;
        }
      }
      .cancelButtonStyle {
        width: 20%;
        &:hover {
          color: $dark;
        }
        @include sm {
          width: 45%;
        }
      }
    }
  }

  .featuredContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;

    h4 {
      font-weight: normal;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  .modifyComments {
    border-top: 1.5px solid $steel-42;
    padding-top:65px;
  }

}
