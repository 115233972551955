@import 'variables.scss';
.messenger {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 72px);
  border: 1px solid #eeeef1;
  -webkit-box-shadow: -1px 2px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px 2px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0 0 5px -1px rgba(0,0,0,0.75);
  @include sm {
    display: flex;
    height: 100vh;
  }
}

.scrollable {
  overflow: auto;
  max-height: 100%;
  min-height: 100%;
}

.sidebarLS {
  background: white;
  width: 100%;
  flex: 0 30%;
  border-right: 3px solid #eeeef1;
  @include sm {
    display: none;
  }
}

.contentLS {
  background: white;
  width: 100%;
  flex: 0 70%;
  @include sm {
    display: block;
    flex: none;
  }
}

.sidebarSM {
  display: none;
  @include sm {
    display: block;
    background: white;
    width: 100%;
  }
}
