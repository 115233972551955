.newsSectionContainer {
  margin: 84px 0 20px;

  .divider {
    width: 100%;
    height: 1px;
    margin-bottom: 60px;
    background: #c5d0d8;
  }
}
