@import 'variables';
.formGroup {
  border: 1.5px solid $dark;
  border-radius: 5px;
  height: 50px;
  &:focus-within {
    border: 0px;
    border-radius: 5px;
    box-shadow: inset 5px 5px 10px 0 #a6abbd, inset -5px -5px 10px 0 #fafbff;
    background-image: linear-gradient(to bottom, #ebecf0, #ebecf0);
    border: solid 1px rgba(255, 255, 255, 0.4);
    h5 {
      display: none;
    }
    .formControl {
      background: transparent;
    }
  }
  h5 {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    top: -6px;
    position: absolute;
    background: $white;
    text-align: center;
    color: $dark;
  }
  .formControl {
    width: 100%;
    border: 0;
    font-size: 16px;
    line-height: normal;
    color: $dark;
    &:focus {
      outline: none;
      background: transparent;
    }
  }
  .error {
    top: -40%;
    position: absolute;
    right: 0px;
    color: red;
    font-size: 12px;
    font-weight: 500;
  }
  .passwordContainer {
    display: flex;
    align-items: center;
    .eyeOutlinedStyle {
      font-size: 15px;
    }
  }
}
.validationError {
  border: 1.5px solid red;
}
.passwordInputImgStyle {
  font-size: 23px;
}