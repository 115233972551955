@import 'variables.scss';
.mobileFiltersContainer {
  display: none;
  @include sm; @include tablets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 26px;
    .dropdownMenuContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1.5px solid $steel-42;
      padding-bottom: 10px;
      font-family: 'Poppins', sans-serif;
      h4 {
        font-size: 16px;
        color: $dark-two;
        font-weight: normal;
        cursor: pointer;
        margin-bottom: 0px;
        margin-right: 35px;
      }
    }
  }
}
.overlay {
  font-size: 15px;
  max-height: 50vh;
  overflow-y: scroll;
}
:global {
  .ant-dropdown {
    font-family: 'Poppins', sans-serif;
  }
}
