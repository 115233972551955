@import 'variables.scss';
.catalogContainer {
  font-family: 'Poppins', sans-serif;
  min-height: 100vh;

  .navigationInfo {
    display: flex;
    flex-direction: row;
    font-size: 15.7px;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 60px;
    @include sm {
      display: none;
    }
    h5 {
      color: $dark;
      text-transform: uppercase;
      &:hover {
        filter: invert(50%);
      }
      cursor: pointer;
    }
    h6 {
      line-height: inherit;
      color: $dark;
    }
  }
  .buttonSuccessStyle {
    height: 50px;
    width: 290px;
    margin-left: 20%;
    margin-top: 12px;
    @include tablets {
      margin-top: 12px;
    }
    @include sm {
      margin-left: 6%;
    }
  }
  .uploadedPdfsContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .uploadedCatalog {
      width: 60%;
      margin-top: 32px;
      @include sm {
        width: 95%;
      }
      .pdfRowContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-content: flex-start;
        margin-bottom: 15px;

        div {
          overflow: hidden;
          white-space: nowrap;
        }
        h5 {
          margin-bottom: 0px;
          padding: 5px;
          font-size: 13px;
          margin-left: 15px;
          padding-right: 15px;
        }
        svg {
          overflow: initial;
        }
      }
      .deletePdfButton {
        height: 30px;
        width: 90px;
        border-radius: 6px;
        border: solid 1px #050713;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        background: $white;
        color: $dark;
        &:focus {
          outline: none;
        }
      }
      .viewPdfButton {
        height: 30px;
        width: 200px;
        border-radius: 6px;
        border: solid 1px #050713;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        background: $white;
        color: $dark;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
