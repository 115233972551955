@import 'variables.scss';
.modalContainer {
  min-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  :global {
    background: rgba(0, 0, 0, 0.6);
    .modal-content {
      align-items: center;
      justify-content: center;
      height: 90vh;
      width: 90%;
      background: transparent;
      padding-top: 20px;
      border: none;
    }
  }
  img {
    height: 75%;
    object-fit: cover;
    border-radius: 15px;
    max-width: 100%;
    @include sm {
      object-fit: contain;
      height: auto;
    }
  }
  .mainButtonStyle {
    background: transparent;
    border: 1.5px solid $white;
    width: 170px;
    height: 50px;
    color: $white;
    margin-top: 34px;
    padding: 0px;
    font-size: 12px;
    font-weight: 500;
  }
}
