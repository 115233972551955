@import 'variables.scss';
.modalContainerStyle {
  min-width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  .modalWrapper {
    margin: 5%;
  }
  .navigationInfo {
    display: flex;
    flex-direction: row;
    font-size: 15.7px;
    font-weight: 600;
    padding-bottom: 10px;
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 70%;
    padding-top: 29px;
    @include sm {
      width: 100%;
      padding-top: 0px;
      button {
        &:last-child {
          min-width: 60%;
        }
      }
    }
  }
  .modalContent {
    border-bottom: 1.5px solid $light-blue-grey;
    border-top: 1.5px solid $light-blue-grey;
    height: 64vh;
    overflow-y: scroll;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    .imageContainer {
      box-shadow: inset 5px 5px 10px 0 #a6abbd, inset -5px -5px 10px 0 #fafbff;
      border: solid 1px rgba(255, 255, 255, 0.4);
      background-image: linear-gradient(318deg, #000000 100%, var($white) -5%),
        linear-gradient(to bottom, #ebecf0, #ebecf0);
      border-radius: 50%;
      height: 95px;
      width: 95px;
      margin-left: 12px;
      margin-right: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      @include tablets {
        height: 76px;
        width: 76px;
        margin-left: 0px;
      }
      @include sm {
        height: 76px;
        width: 76px;
        margin-left: 0px;
      }
    }
    .selectedIcon {
      box-shadow: 0 5px 10px 0 rgba(166, 171, 189, 0.7), 0 -21px 10px 0 #fafbff;
      border: solid 3px #050713;
      background-image: linear-gradient(318deg, #000000 100%, var($white) -5%),
        linear-gradient(to bottom, var($white), var($white));
    }
  }
}
