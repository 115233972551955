@import 'variables.scss';
.socialLinksContainer {
  font-family: 'Poppins', sans-serif;
  background-color: #050713;
  padding-bottom: 43px;
  @include sm {
    padding-bottom: 25px;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    color: $white;
    padding-top: 27px;
    margin-bottom: 18px;
    text-align: right;
    @include sm {
      text-align: left;
    }
  }
  .formGroup {
    border-color: $white;
    background: transparent;
    @include sm {
      margin-bottom: 20px;
    }
    &:focus-within {
      background-image: transparent;
      border-color: transparent;
      box-shadow: none;
    }
    color: $white;
    h5 {
      color: $white;
      background: $dark;
    }
    .formControl {
      color: $white;
      background: transparent;
      &:focus {
        background: transparent;
        color: $white;
      }
    }
    .passwordContainer {
      img {
        width: 20px;
        object-fit: contain;
      }
    }
  }
  .linksRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include sm {
      flex-direction: column;
    }
  }
}
