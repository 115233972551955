@import 'variables.scss';
.conversation-list {
    display: flex;
    flex-direction: column;
  }

  .empty-search-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 10px;
    
    img {
      width: 85px;
      object-fit: contain;
      align-self: center;
    }
    h6 {
      font-size: 25px;
      line-height: 1.03;
      letter-spacing: 0.13px;
      font-weight: normal;
      margin-bottom: 0px;
    }
    p {
      font-size: 13px;
      letter-spacing: 0.06px;
    }
  }