@import 'variables.scss';
.modalContainer {
  :global {
    min-width: 38%;
    .modal-content {
      .modal-header {
        .close {
          font-size: 40px;
          margin-right: 0px;
          span {
            opacity: 0.6;
            font-weight: 400;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    @include sm {
      margin: 0;
      height: 100%;
      .modal-content {
        border: none;
        border-radius: 0;
        height: 100%;
      }
    }
  }
  .modalHeader {
    border-bottom: none;
  }
  .modalBody {
    font-family: 'Poppins', sans-serif;
    @include sm {
      height: 100%;
      justify-content: center;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    img {
      width: 15%;
      padding-bottom: 15px;
      align-self: center;
    }
    h1 {
      font-size: 28px;
      line-height: 1.32;
      letter-spacing: 0.1px;
      color: $dark;
    }
    h2 {
      font-size: 16px;
      letter-spacing: 0.06px;
      color: $dark;
      padding-bottom: 40px;
    }
    h3 {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.32px;
      color: $dark;
      padding-top: 20px;
      a {
        color: $dark;
        text-decoration: underline;
      }
    }
    h4 {
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: 0.07px;
      color: #4f5063;
      &:hover {
        cursor: pointer;
      }
    }
    form {
      width: 100%;
    }
    .forgotPasswordInstructions {
      font-size: 16px;
      letter-spacing: 0.06px;
      white-space: pre-line;
      padding-bottom: 18px;
      color: $dark;
      @include sm {
        white-space: normal;
      }
    }
  }
}
.formContainer {
  width: 80%;
  .buttonSuccessStyle {
    height: 50px;
    line-height: 0px;
  }
  @include sm {
    width: 100%;
  }
}
.animationStyle {
  :global {
    .modal-content {
      margin-top: 50%;
      background: transparent;
      border: none;
    }
  }
}
