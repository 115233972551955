@import 'variables.scss';
.message {
  display: flex;
  flex-direction: column;
  a:link {
    color: white;
    background-color: transparent;
  }
  a:visited {
    color: white;
    background-color: transparent;
  }
  a:hover {
    color: black;
    background-color: transparent;
  }
  a:active {
    color: white;
    background-color: transparent;
  }
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
  overflow-wrap: anywhere;
}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.start .bubble-container .bubble {
  border-top-left-radius: 20px;
  margin-bottom: 15px;
}

.message.end {
  margin-bottom: 15px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.message.mine .bubble-container .bubble {
  background: #007aff;
  color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.message-file {
  color: black;
  margin: 0px;
  u {
    margin-left: 5px;
  }
}

.message.mine .bubble-container .bubble a .message-file {
  color: white;
}
