@import 'variables.scss';
.subscriptionPackages {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .packages {
    display: flex;
    flex-direction: column;
    align-items: center;

    .packagesContainer {
      margin: 66px 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      @include tablets {
        flex-direction: column;
      }
    }
  }

  .packageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 14px;
    border: $package-item-border;

    &:first-child, &:last-child {
      position: relative;

      @include tablets {
        position: static;
      }
    }

    &:first-child {
      left: 8px;
      z-index: 1;
    }

    &:last-child {
      right: 8px;
      z-index: 1;
    }

    &.recommendedPackageContainer {
      background: $dark-three;
      width: 356px;
      height: 634px;
      z-index: 2;

      .packageContent {
        margin: 19px 20px 20px;
      }

      @include tabletsLandscape {
        width: 256px;
        height: 455px;

        .packageContent {
          margin: 19px 20px 20px;
        }
      }
    }

    &.defaultPackageContainer {
      width: 300px;
      height: 563px;
      background: $white;

      .packageContent {
        margin: 15.5px 14.5px 14.5px;
      }

      @include tabletsLandscape {
        width: 215px;
        height: 404px;

        .packageContent {
          margin: 11.5px 13px 13px;
        }
      }
    }

    @include tablets {
      width: 311px!important;
      height: auto!important;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .packageContent {
        width: calc(100% - 26px);
        margin: 11.5px 13px 13px;
      }
    }

    .default {
      color: $dark-three;
    }

    .recommended {
      color: $darker-white;
    }


    .packageTitle {
      font: {
        style: normal;
        weight: 600;
      }
      text-transform: uppercase;

      &.default {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 12px;

        @include tabletsLandscape {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 8.6px;
        }
      }

      &.recommended {
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 15px;

        @include tabletsLandscape {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 10.9px;
        }
      }

    }

    .packageDescription {
      font: {
        size: 14px;
        weight: 300;
        style: normal;
      }
      line-height: 18px;
      padding: 0;

      &.recommended {
        color: #808080;
        margin-bottom: 20px;
      }

      &.default {
        margin-bottom: 15px;
      }

      @include tabletsLandscape {
        font-size: 10px;
        line-height: 12.9px;

        &.recommended {
          margin-bottom: 14px;
        }

        &.default {
          margin-bottom: 10.5px;
        }
      }
    }

    .packagePrice {
      font: {
        weight: 600;
        style: normal;
      }
      text-transform: uppercase;

      &.recommended {
        font-size: 35px;
        line-height: 41.45px;

        @include tabletsLandscape {
          font-size: 25px;
          line-height: 29.8px;
        }
      }

      &.default {
        font-size: 30px;
        line-height: 36px;

        @include tabletsLandscape {
          font-size: 22px;
          line-height: 25.9px;
        }
      }
    }

    .divider {
      background: #B8B8B8;
      width: 100%;
      height: 0.5px;

      &.default {
        margin: 20px 0 24px;

        @include tabletsLandscape {
          margin: 14px 0 17px;
        }
      }

      &.recommended {
        margin: 23px 0 30px;

        @include tabletsLandscape {
          margin: 16.5px 0 21.5px;
        }
      }
    }

    .bizbookRecommends {
      background: $success-color;
      color: $dark-three;
      font: {
        style: normal;
        size: 12px;
        weight: 700;
        family: 'Nunito Sans', sans-serif;
      }
      line-height: 16px;
      text-transform: uppercase;
      border-radius: 0 0 9px 9px;
      width: 178px;
      height: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      padding: 4px 5px;

      @include tabletsLandscape {
        font-size: 9px;
        line-height: 12.3px;
        height: 17px;
        width: 131px;
        border-radius: 0 0 6.5px 6.5px;
        padding: 3px 3.5px;
      }
    }

    .includedItems {
      margin-bottom: 30px;

      @include tabletsLandscape {
        margin-bottom: 21px;
      }
    }

    .recommendedIncludedItems {
      margin-bottom: 36px;

      @include tabletsLandscape {
        margin-bottom: 25px;
      }
    }

    .buttonSuccessStyle {
      width: 100%;
      height: 50px;
      font: {
        family: 'Nunito Sans', sans-serif;
        size: 15px;
        weight: 700;
        style: normal;
      }
      line-height: 20px;

      @include tabletsLandscape {
        height: 36px;
        font-size: 12px;
        line-height: 16px;
        padding: 0;
      }
    }

    .recommendedButtonSuccessStyle {
      background: $darker-white;
      color: $dark-three;
    }

    .buttonContainer {
      outline: none;
      border: none;
      background: transparent;
      font-size: 15px;
      font-weight: 600;
      color: $dark;

      @include sm {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        right: 15px;
        bottom: 25px;
      }
      img {
        display: none;
        @include sm; @include tablets {
          display: block;
          width: 14px;
          align-self: center;
        }
      }
    }
  }

  .finePrint {
    display: flex;
    flex-direction: column;

    span:first-child {
      margin-bottom: 20px;
    }
  }
}

.detailedItemContainer {
  display: flex;
  flex-direction: row;
  width: 260px;
  align-items: center;
  margin-bottom: 16px;

  .detailedItem {
    font: {
      size: 14px;
      weight: 400;
      style: normal;
      family: 'Poppins', sans-serif;
    }
    line-height: 20px;
    margin: 0 0 0 8px;
  }

  @include tabletsLandscape {
    margin-bottom: 11px;
    width: 190px;

    .detailedItem {
      font-size: 10px;
      line-height: 14px;
      margin-left: 5.7px;
    }
  }

  @include tablets {
    width: 100%!important;
  }

  .included {
    color: $dark-three;
  }

  .notIncluded {
    color: $not-included-gray;
  }
}
