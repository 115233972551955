@import 'variables.scss';
.progressNotSatisfiedContainerStyle {
  height: calc(100vh - 72px);
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  @include tablets {
    height: calc(100vh - 52px);
  }

  img {
    width: 100%;
    height: calc(100vh - 71px);
    object-fit: cover;
    position: absolute;
    z-index: -1;
    @include tablets {
      height: calc(100vh - 52px);
    }
  }
  h3 {
    font-size: 35px;
    color: $white;
    width: 100%;
    text-align: center;
  }
  h4 {
    font-size: 25px;
    color: $success-color;
    width: 100%;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    @include sm {
      top: 35%;
    }
  }
}

.progressSatisfiedContainer {
  min-height: 100vh;
  position: relative;
}

.imageloaderStyle {
  margin-left: 50%;
  transform: translate(-50%, 50%);
  width: 300px;
}
