@import 'variables.scss';
.navbar {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  background-color: $dark;
  z-index: 10;
  @include sm {
    position: sticky;
    transition: all 0.3s ease-in;
    top: 0;
    padding-top: 10px;
    padding-bottom: 5px;
    height: $mobile-navbar-height;
    &.container {
      display: flex;
      align-items: center;
    }
  }
}

.containerRow{
  display:flex;
  flex-direction: row;
}

.companyLogo{
  display: flex;
  padding-right:10px;
  align-items:center;
  padding-top: 14px;
  @include sm; @include tablets{
    padding-right:0;
    padding-top: 0;
  }
}

.bizbookLogo{
  display:flex;
  flex-direction: row;
  align-items:center ;
  @include sm; @include tablets{
    display: none;
  }
}
 
.userInfo{
  display:flex;
  flex-direction: row;
  align-items:center ;
  @include sm; @include tablets{
    display: none;
  }
}
.tabs{
  display: flex;
  @include sm; @include tablets{
    display: none;
  }
}
.sideMenu{
  display: none;
  @include sm; @include tablets{
    display: flex;
    flex-direction: row;
  }
}

.numberOfViews{
  display: none;
  border: none;
  @include tablets; @include sm{
    padding-left: 5px;
    display: flex;
    justify-content: flex-start;
  }
}
.notifications{
  display: none;
  border: none;
  @include tablets; @include sm{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.companyLogoContainer{
  display: none;
  @include sm {
    display: block;
    .companyLogoSm{
      margin-top: 34px;
      margin-bottom: 15px;
      padding-left: 3px;
    }
  }
  @include tablets{
    display: block;
    .companyLogoSm{
      margin-top: 34px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
    }
  }
}

.navbarCollapse{
  display: none;
}
@include tablets {
  .navbarCollapse {
    height: 0;
    width: 100%;
    transition: step-start 0.3s ease;
    position: absolute;
    background:$dark;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5%;
    &.show {
      display: flex;
      height: 55vh;
    }
  }
}
@include sm {
  .navbarCollapse {
    height: 0;
    width: 70%;
    transition: step-start 0.3s ease;
    position: absolute;
    background:$dark;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5%;
    &.show {
      display: flex;
      height: 100vh;
    }
  }
}

.buttonMenu{
  display:flex;
  align-items:center;
}

.backToProfile {
  color: $dark;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: normal;
  background-color: $success-color;
  display: flex;
  align-items: center;
  font-size:14px;
  &:hover {
    color: $dark;
  }
  @include tablets; @include sm{
    padding-bottom: 25px;
    padding-top: 5px;
  }
}
.backToProfileContainer {
  line-height:71px;
  margin: -.5rem 0;
  @include tablets {
    display: none;
  }
}
.backToProfileIcon{
  font-size:16px;
  margin-top:-1px;
}
.hideNav {
  @include sm {
    display: none;
  }
}