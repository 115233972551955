@import "variables.scss";

.forYou {
  width: 31%;

  @media (min-width: 768px) and (max-width: 991px) {
    width: 38%;
  }

  .forYouTitle {
    font-size: 16px;
    color: #212121;
    text-transform: capitalize;
    font-weight: bold;
  }

  .slider {
    width: 100%;
    height: 2px;
    margin: 5px 0 30px;
    background: #eee;
  }

  .sliderTag {
    width: 30px;
    height: 2px;
    background: #04050f;
    position: relative;
    top: -32px;
  }

  .articlesContainer {
    .article {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 28px;

      &:last-child {
        margin-bottom: 0;
      }

      .imgContainer {
        width: 35%;

        img {
          width: 100%;
          object-fit: cover;
          height: 93px;
        }
      }

      .contentContainer {
        width: 61%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .link {
          &:hover {
            text-decoration: none;
          }

          .title {
            max-height: 64px;
            font-size: 15px;
            font-weight: 500;
            line-height: 1.4;
            letter-spacing: -0.12px;
            color: #212121;
            overflow: hidden;

            &:hover {
              color: $success-color;
            }
          }
        }
      }

      @media (min-width: 575px) and (max-width: 767px) {
        flex-direction: column;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .imgContainer,
        .contentContainer {
          width: 100%;
        }
      }
    }
  }

  .showMoreButton {
    display: flex;
    flex-direction: row;
    border: none;
    background: none;
    width: 100px;
    margin: 5px 0;
    padding: 0;
    margin-bottom: 10px;

    &:hover {
      transition-duration: 0.25s;
      color: $success-color;
    }

    .arrow {
      margin-left: 5px;
      transform: rotate(90deg);
    }
  }

  @media (max-width: 575px) {
    width: 100%;
    margin-top: 30px;
  }
}

.forYouArticleView {
  @media (min-width: 768px) and (max-width: 991px) {
    width: 100%;

    .articlesContainer {
      flex-direction: row;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: baseline;

      .article {
        width: 325px;
      }
    }
  }
}
