@import 'variables.scss';
.modalContainerStyle {
  min-width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  .modalWrapper {
    margin: 5%;
  }
  .navigationInfo {
    display: flex;
    flex-direction: row;
    font-size: 15.7px;
    font-weight: 600;
    padding-bottom: 10px;
    @include sm {
      display: none;
    }
    h5 {
      font-size: 15.7px;
      font-weight: 600;
      color: $grey;
    }
    h6 {
      font-size: 15.7px;
      font-weight: 600;
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 70%;
    padding-top: 29px;
    border-top: 1.5px solid $light-blue-grey;
  }
  .modalContent {
    border-top: 1.5px solid $light-blue-grey;
    padding-top: 30px;
    color: $dark;
    h4 {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 0px;
    }
    p {
      padding-left: 20px;
      margin-bottom: 0px;
      font-weight: 600;
    }
    .requestedPackageTypeText {
      display: flex;
      flex-direction: row;
      padding-left: 4.5%;
    }
    .inputsContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .textareaStyle {
        width: 100%;
        height: auto;
        .textAreaLabel {
          top: -6px;
          position: relative;
          width: 100px;
          margin: 0;
        }
        textarea {
          width: 100%;
          resize: none;
        }
        .error {
          text-align: right;
          position: absolute;
          top: -19px;
          margin: 0;
        }
      }
      .reCaptchaContainer {
        .reCaptchaErrorText {
          color: red;
          font-size: 12px;
          font-weight: 500;
          left: 304px;
          transform: translate(-100%, 0px);
          position: absolute;
          width: fit-content;
        }
        .reCaptcha {
          margin-top: 20px;
          padding-bottom: 30px;
        }
      }
    }
  }
}
