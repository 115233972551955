@import "variables.scss";

.categoryContainer {
  width: 185px;
  height: 28px;
  background-color: $success-color;
  display: flex;
  align-items: center;

  .category {
    color: #03050e;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    margin: 0 8px;
  }
}

.smallCategoryContainer {
  position: absolute;
  left: calc(-118.33% + 10px);
  top: 10px;

  @media (max-width: 991px) {
    left: 10px;
    top: -263px;
  }
}

.dateContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  .clockIcon {
    color: $success-color;
  }

  .date {
    margin-left: 8px;
    color: #a0a0a0;
    font-size: 11px;
    text-transform: uppercase;
  }

  .dateBanner {
    color: #fff;
  }
}
