@import 'variables.scss';
.filtersContainer {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 130px;
  @include sm; @include tablets {
    display: none;
  }
  h3 {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: $steel-42;
    margin-bottom: 35px;
  }
  h4 {
    font-size: 16px;
    color: $dark-two;
    font-weight: normal;
    margin-bottom: 12px;
    cursor: pointer;
  }
  .horizontalBreakLineStyle {
    border-top: 1.5px solid $steel-42;
    width: 80%;
    height: 2px;
    margin-bottom: 24px;
  }
  .dropdownMenuContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
  }
  .tagsContainer {
    max-width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
    p {
      font-size: 13px;
      padding: 3px 15px;
      border-radius: 15px;
      border: solid 0.5px #9da3b4;
      cursor: pointer;
      margin-right: 8px;
      &:hover {
        opacity: 0.5;
      }
    }
    .activeTagStyle {
      background: $success-color;
      border: none;
    }
  }
  .industryInputSearchStyle {
    width: 80%;
    position: relative;
    border: 1.5px solid $steel-42;
    :global {
      .ant-select {
        width: 100%;
        padding: 0px 15px;
        &:focus {
          outline: none;
          border: none;
        }
        .ant-select-selector {
          border: none;
          background: transparent;
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          padding: 0px;
          box-shadow: none;
          .ant-select-selection-search {
            left: 0;
            color: $dark;
          }
          .ant-select-selection-placeholder {
            color: $dark;
            opacity: 0.6;
          }
        }
        &:focus {
          outline: none;
          border: none;
        }
      }
    }
  }
}
