@import 'variables.scss';
.notificationContainer {
  display: flex;
  flex-direction: row;
}

.notificationContainerIcon {
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.5;
  }
  @include sm {
    padding-top: 0;
  }
  @include tablets {
    padding-top: 0;
  }
}

.notificationIcon {
  border-radius: 4px;
  border: solid 1px #4f5063;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  @include sm {
    border-radius: 0;
    border: 0;
    padding: 0;
  }
  @include tablets {
    border-radius: 0;
    border: 0;
    padding: 0;
  }
  .badge {
    :global {
      .ant-badge-count {
        background-color: $success-color;
        color: $dark;
      }
    }
  }
}

.dropdownContainer {
  padding: 0;
  width: 400px;
  max-height: 700px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.menuItemStyle {
  &:hover {
    background: $success-color;
  }
}

.menuLinkStyle {
  font-family: 'Poppins', sans-serif;
  margin: 5px !important;
  background: transparent;
  &:hover {
    background: $success-color;
  }
  .companyLogo {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: lightgray;
  }
  .linkContent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 70px;
  }
  .title {
    .companyName {
      font-weight: bold;
    }
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin: 5px 0 0;
    max-height: 32px;
    overflow-wrap: anywhere;
    white-space: normal;
    overflow: hidden;
    width: 265px;
  }
  .content {
    margin: 5px 0;
    font-weight: normal;
    font-size: 13px;
    width: 32ch;
    overflow-wrap: anywhere;
    white-space: normal;
    overflow: hidden;
    height: 13px;
    line-height: 13px;
  }
  .notificationReceivedTime {
    margin: 0;
    font-size: 12px;
    line-height: 12px;
  }
}
.unreadNotification {
  background: $steel-42;
}
.noNotificationsMenuItemStyle {
  padding: 15px;
  width: 250px;
  align-self: center;
  &:hover {
    background: $white;
  }
  h4 {
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 0;
    text-align: center;
    font-weight: 500;
  }
}


