@import 'variables.scss';
.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.active-conversation {
  background: rgba(0, 0, 0, 0.1);
}

.conversation-list-item:hover {
  background: $success-color;
  cursor: pointer;
}

.conversation-list-item-info {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
}


.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation-title {
  font-size: 14px;
  text-transform: capitalize;
  margin: 0;
  font-weight: normal;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.avatar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1px;
  padding: 8px;
  .avatar {
    background: $dark;
  }
  .avatar-name {
    color: $white;
    background: $dark;
    text-transform: uppercase;
    font-weight: bold;
  }
}
.active-status {
  height: 10px;
  width: 10px;
  background: #1c7430;
  border-radius: 5px;
  border: none;
  margin-left: 10px;
}

