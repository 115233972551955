@import 'variables.scss';

.companyLogo{
 display: flex;
 justify-content: center;
 align-items: center;
 padding-left: 5px;
}

.companyImgUrl{
    object-fit: contain;
    height: 30px;
}