@import 'variables.scss';
.modalContainer {
  height: 100vh;
  min-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.13);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  @include sm {
    margin-bottom: 20px;
  }
  :global {
    background: rgba(0, 0, 0, 0.6);
    .modal-content {
      align-items: center;
      justify-content: center;
      width: 350px;
      background: $white;
      padding: 25px 20px;
      border: none;
    }
  }
  .ratingSliderContainer {
    width: 80%;
  }
  h3 {
    font-size: 12px;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
  }
  .customButtonStyles {
    height: 50px;
    margin-top: 15px;
    width: 100%;
    &:disabled {
      &:hover {
        background: $steel-42;
      }
    }
  }
  .ratingSlider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0px;
      color: $dark;
    }
  }
}
