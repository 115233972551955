@import 'variables.scss';
.addedProductContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  img {
    width: 125px;
    object-fit: contain;
  }
  h4 {
    font-size: 36px;
    font-weight: normal;
    line-height: 1.03;
    letter-spacing: 0.13px;
    margin-bottom: 12px;
    margin-top: 23px;
    @include sm {
        font-size: 28px;
    }
  }
  h5 {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.06px;
    margin-bottom: 42px;
  }
  .buttonSuccessStyle {
      height: 50px;
      width: 320px;
  }
}
