@import 'variables.scss';
.message-list-container {
  padding: 0px 20px;
  position: relative;
  height: 100%;
  @include sm {
    padding: 0px 10px;
  }
  .compose .ant-upload svg {
    cursor: pointer;
  }
}
.message-list-back {
  height: 50px;
  padding-left: 10px;
}

.message-list {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
