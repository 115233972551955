@import 'variables.scss';
.feedCardContainer {
  font-family: 'Poppins', sans-serif;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 #f2f5f9;
  border: solid 1px #c1cbd7;
  .titleContainerRow {
    margin-top: 30px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    h3 {
      width: 80%;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.5;
    }
    .imageContainer {
      position: absolute;
      top: 0;
      right: 0;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .expireAtStyle {
    width: fit-content;
    border-radius: 4px;
    background: $dark;
    font-size: 12px;
    line-height: 1.67;
    color: $white;
  }
  .mainImageContainer {
    position: relative;
    img {
      margin-top: 22px;
      margin-bottom: 22px;
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 6px;
    }
    .viewCountContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 70%;
      left: 11px;
      background-color: $white;
      border-radius: 20px;
      box-shadow: 0 15px 55px 0 rgba(157, 163, 180, 0.2);
      width: 100px;
      height: 40px;
      padding-left: 15px;
      img {
        width: 24px;
        height: 15px;
        object-fit: cover;
        margin: 0;
      }
      p {
        padding-left: 10px;
        font-size: 15px;
        font-weight: 600;
        color: $dark;
        margin: 0;
      }
    }
  }
  h4 {
    font-size: 14px;
    font-weight: 500;
    color: $dark;
    margin-bottom: 3px;
    text-transform: capitalize;
  }
  h5 {
    opacity: 0.54;
    font-size: 13px;
    line-height: 1.54;
    color: $dark;
  }
  .customButtonStyles {
    width: 100%;
    height: 50px;
    font-size: 13px;
    padding: 0px;
  }
  .tagsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
    p {
      font-size: 13px;
      padding: 3px 15px;
      border-radius: 15px;
      border: solid 0.5px #9da3b4;
      cursor: pointer;
      margin-right: 8px;
    }
  }
  .additionalPicturesContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 30px 0px;
    overflow-x: auto;
    img {
      width: 95px;
      height: 92px;
      object-fit: cover;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}
