@import 'variables.scss';
.smallerArticles {
  margin: 69px 0 121px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 991px) {
    flex-direction: column;
    margin-top: 30px;

    .article {
      width: 100%;

      &:first-child {
        margin-bottom: 30px;
      }
    }
  }

  @media (max-width: 575px) {
    margin: 20px 0 75px;

    .article {
      flex-direction: column;
      align-content: space-between;
      height: 615px;

      .imgContainer,
      .contentContainer {
        width: 100%;
      }

      .contentContainer {
        align-content: space-between;
        height: 300px;
      }
    }
  }
}

.article {
  height: 300px;
  width: 49%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .imgContainer {
    width: 37%;
    height: 300px;

    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 58%;
  }
}

.title {
  max-height: 62px;
  margin: 0;
  line-height: 1.41;
  font-size: 22px;
  font-weight: bold;
  color: #212121;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: $success-color;
  }
}

.description {
  height: 79px;
  margin: 0;
  display: flex;
  line-height: 1.73;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  color: #050713;
}

.link {
  &:hover {
    text-decoration: none;
  }

  .readButton {
    width: 158px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #050713;
    border: none;

    &:hover {
      background: $success-color;
      transition-duration: 0.5s;
    }

    span {
      color: $white;
      letter-spacing: 0.28px;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

.divider {
  display: none;

  @media (max-width: 575px) {
    display: block;
    height: 1px;
    background-color: #c5d0d8;
    width: 100%;
  }
}
