@import 'variables.scss';
.registerConfirmedContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-height: 100vh;
}
.textRegisterConfirmedSucces{
  font-family: Poppins;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: 0.13px;
  text-align: center;
  color: #050713;
  padding-bottom: 5px;

  @include tablets {
    font-size: 26px;
  }
  @include sm {
    font-size: 26px;
  }
}
.buttonHomePage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center; 
    margin-top: 2%;
}

.textRegistterConfirmedEmail{
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.06px;
  text-align: center;
  color: #050713;
  @include tablets {
    font-size: 12px;
  }
  @include sm {
    font-size: 12px;
  }
}
.buttonSuccessStyle {
    width: 50%;
    height: 50px;
    padding: 0px;
    &:hover:disabled {
      opacity: 0.5;
      background-blend-mode: soft-light;
      background-color: $dark;
      color: $white;
    }
  }