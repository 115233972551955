@import 'variables.scss';
.addPdfButtonStyle {
  height: 30px;
  width: 120px;
  border-radius: 6px;
  border: solid 1px #050713;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.28px;
  background: $white;
  text-transform: uppercase;
  &:focus {
    outline: none;
  }
}
.uploadContainer {
  flex: 0 0 auto;
  position: relative;
  margin-top: 25px;
  :global {
    .lazy-load-image-background {
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 100%;
      background: transparent;
    }
  }
  .uploadContainer {
    width: 60%;
    height: 150px;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: inset 5px 5px 10px 0 #a6abbd, inset -5px -5px 10px 0 #fafbff;
    border: solid 1px rgba(255, 255, 255, 0.4);
    @include sm {
      width: 90%;
    }
  }
  .loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 220px;
    .loaderImgStyle {
      width: 20%;
      object-fit: contain;
    }
  }
  .imageUploadButtonContainer {
    height: 70px;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, 0%);
    :global {
      .ant-upload {
        height: 50px;
        background: transparent;
        border: none;
        margin: 0;
      }
    }
  }
  .uploadedPdfContainer {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, 0px);
    h5 {
      margin-bottom: 0;
      padding: 5px;
      font-size: 13px;
      @include tablets {
        width: 150px;
        overflow: hidden;
      }
      @include sm {
        width: 150px;
        overflow: hidden;
      }
    }
  }
}
.customMargin {
  margin-right: 15px;
}
