@import 'variables.scss';
.aboutComponent {
  margin-top: 50px;
  font-family: 'Poppins', sans-serif;

  .aboutContainer {
    width: 100%;

    hr {
      margin: 50px 0;
    }

    .aboutContent {
      display: flex;
      width: 100%;
      justify-self: center;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 110px;

      @include tablets {
        flex-direction: column;
        margin-top: 0;
      }

      .aboutBizbookImg {
        width: 50%;
        flex-basis: 50%;
        height: 393.5px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 393.5px;
          object-fit: cover;
          object-position: 65% 50%;
        }

        @include tablets {
          width: 80%;
          flex-basis: 80%;

          img {
            width: 100%;
            height: auto;
          }
        }

        @media (max-width: 575px) {
          width: 100%;
          flex-basis: 100%;
        }
      }

      .descriptionContainer {
        margin-left: 60px;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include tablets {
          width: 80%;
          margin-left: 0;
          margin-top: 50px;
        }

        @media (max-width: 575px){
          width: 100%;
        }

        .bizbookComputerIcon {
          width: 70px;
        }

        .divider {
          width: 40px;
          height: 3px;
          background-color: #050713;
          margin: 22px 0;
        }

        .description {
          font-size: 15px;
          line-height: 1.73;
          color: #050713;
        }
      }
    }
  }

  .title {
    margin-top: 33px;
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
    color: #050713;
    @media (max-width: 575px) {
      font-size: 20px;
    }
  }
}
