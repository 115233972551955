@import 'variables.scss';
.modalBody {
  :global {
    min-width: 38%;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: right;
    text-align: center;
    padding:15px 15px 0 15px;
    font-size:20px;
    @include sm {
      margin: 0;
      height: 100%;
      border: none;
      border-radius: 0;
      height: 100%;
      width: 100%;
      justify-content: center;
    }
    button {
      line-height: 0px;
      display:inline;
      margin-bottom:15px;
    }
  }
}
