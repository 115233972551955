@import 'variables.scss';

.news {
  margin: 50px 0 180px;
  display: flex;
  flex-direction: column;

  .articles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 66px;

    .noArticles {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .noArticlesText {
        font-size: 18px;
        color: #03050e;
        margin-bottom: 0;
      }
    }

    @media (max-width: 575px) {
      flex-direction: column;
    }
  }

  @include sm {
    margin: 0 0 90px;
  }
}

.newsArticles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .articleLeft {
    margin-right: 2px;

    .article,
    .articleImg,
    .overlay, {
      height: 500px;
    }

    .overlay {
      top: -500px;
    }

    .articleContent {
      top: -650px;
    }

    .noTag {
      top: -620px;
    }
  }

  .articlesRight {
    display: flex;
    flex-direction: column;

    .article,
    .articleImg,
    .overlay {
      height: 249px;
    }

    .article:first-child {
      margin-bottom: 2px;
    }

    .overlay {
      top: -249px;
    }

    .articleContent {
      top: -400px;
    }

    .noTag {
      top: -369px;
    }
  }

  .articleLeft, .articlesRight {
    width: calc((100% - 2px) / 2);
    height: 500px;
  }

  .article {
    .articleImg {
      width: 100%;
      object-fit: cover;
    }

    .overlay {
      width: 100%;
      background-color: rgba(0, 0, 0, 0.35);
      position: relative;
    }

    .articleContent {
      width: 90%;
      position: relative;
      left: 35px;

      .link {
        &:hover {
          text-decoration: none;
        }

        .articleTitle {
          color: $white;
          font-size: 18px;
          font-weight: bold;
          line-height: 1.33;
          width: 60%;
          margin: 11px 0 22px;
          height: 48px;
          overflow: hidden;

          &:hover {
            color: $success-color;
          }
        }
      }
    }
  }

  @include tablets {
    flex-direction: column;

    .articleLeft, .articlesRight {
      &, .article {
        width: 100%;
        margin-right: 0;
      }
    }

    .articleLeft {
      margin-bottom: 2px;

      &,
      .article,
      .articleImg,
      .overlay {
        height: 249px;
      }

      .articleContent {
        top: -399px;
      }

      .noTag {
        top: -369px;
      }

      .overlay {
        top: -249px;
      }
    }
  }
}


