.newsArticleContainer {
  margin: 66px 0 95px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .newsArticle {
    width: 58%;

    .title {
      font-size: 32px;
      font-weight: bold;
      line-height: 1.31;
      color: #212121;
      margin: 18px 0;
    }

    .dateAndShareContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: space-between;

      .socialShare {
        margin-top: 0;
        padding: 0;
      }
    }

    .content, .video {
      margin: 18px 0;
      width: 100%;
    }

    .content img {
      width: 100%;
    }

    .imgSlide {
      img {
        width: 100%;
      }

      p {
        font-size: 15px;
        margin: 10px 0 0;
        color: #9294ad;
      }
    }
  }

  @media (max-width: 600px) {
    padding-top: 85px;
    margin-top: 0;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    margin-top: 40px;

    .newsArticle {
      width: 100%;
      margin-bottom: 15px;

      .dateAndShareContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;

        .socialShare {
          margin-top: 15px;
          margin-left: 0;
        }
      }
    }
  }
}
