@import 'variables.scss';
.footer {
    font-family: "Poppins", sans-serif;
    background-color: #050713;
    display: flex;
    align-items: center;
    padding-top: 40px;
    @include sm {
        padding-top: 0px;
    }
}
.bizbookLogo {
    display: flex;
    flex-direction: row;
    .bizbookLogoImg {
        object-fit: contain;
        height: 35px;
        align-self: center;
    }
    .bizbookLogoTitle {
        color: white;
        font-weight: 600;
        font-size: 39px;
        margin-bottom: 0;
    }
    &:hover {
        text-decoration: none;
    }
    @include sm {
        .bizbookLogoImg {
            height: 25px;
        }
        .bizbookLogoTitle {
            display: none;
        }
    }
}
.footerMain {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: thin;
    border-bottom-color: white;
    padding: 0px;
    @include sm {
        padding: 15px 0px;
    }
    .socialLinksContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 20px;
            object-fit: contain;
            padding-left: 30px;
            align-self: center;
            &:hover {
                opacity: 0.7;
            }
        }

        .followUsText {
            font-size: 16px;
            text-transform: uppercase;
            color: white;
            margin-bottom: 0;
            @include sm {
                display: none;
            }
        }
    }
}
.marketMakersContainer {
    display: flex;
    justify-content: space-between;
    @include sm {
        display: none;
    }
    img {
        object-fit: contain;
    }
    .firstImage {
        width: 20%;
    }
    .secondImage {
        width: 25%;
    }
    .thirdImage {
        width: 27%;
    }
}