@import 'variables.scss';

.userInfoContainer {
  display: flex;
  justify-content: center;
  border-left: 1px solid #4f5063;
  align-items: center;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  .avatar {
    background: $white;
  }
  .avatarName {
    color: $dark;
    background: $white;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.userData {
  display: flex;
  flex-direction: column;
}

.userName {
  color: white;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.emailUserInfo {
  color: $white;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.userMenu {
  display: flex;
  align-items: center;
  padding: 10px;
}
