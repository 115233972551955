@import 'variables.scss';
.whatWeDoMainContainer {
  font-family: 'Poppins', sans-serif;
  padding-top: 40px;
  padding-bottom: 40px;
}
.productItemContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 25px;
  .inputSectionTitle {
    width: 100%;
    background: transparent;
    color: $dark;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3;
    @include tablets {
      line-height: normal;
      font-size: 14px;
    }
  }
  .horizontalBreakline {
    border: 1px solid #191924;
    background: black;
    width: 25%;
    margin-bottom: 5px;
  }
  .inputSectionDescription {
    width: 100%;
    background: transparent;
    color: $dark;
    border-radius: 4px;
    resize: none;
    font-size: 16px;
    line-height: 1.5;
    @include tablets {
      font-size: 12px;
    }
  }
  .textCounterContainer {
    width: 100%;
    margin-bottom: 0px;
    text-align: right;
    font-size: 12px;
    transform: translate(0px, 3px);
  }
  .sectionTitleContainerViewMode {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 80px;
    @include sm {
      margin-top: 30px;
      margin-bottom: 15px;
      min-height: auto;
    }
  }
  .sectionTitleTextViewMode {
    border: none;
    margin-bottom: 0px;
  }
}
.productsAndServicesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
h4 {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 44px;
}
