@import 'variables.scss';
.colorPaletteWrapper{
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  .colorPaletteColorMain {
    flex: 0 0 60%;
  }
  .colorPaletteColorsWrapper {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    .colorPaletteColorSecondaryFirst{
      flex: 0 0 33%;
      opacity: 0.6;
    }
    .colorPaletteColorSecondarySecond {
      flex: 0 0 33%;
      opacity: 0.3;
    }
    .colorPaletteColorSecondaryThird {
      flex: 0 0 33%;
      opacity: 0.07;
    }
  }
}
