@import "variables.scss";

.commentEdit {
    margin-top: 30px;
    .commentArea {
      max-width: 741px;
      width: 100%;
      height: 170px;
      border: solid 1px #c5d0d8;
      background-color: #fcfcfc;
      margin-bottom: 21px;
      padding: 21px 28px;
      font-size: 17px;
      font-family: Arial;
      line-height: 1.82;
    }
    .buttonContainer {
      display: flex;
      button {
        height: 60px;
        margin-right: 14px;
        padding: 18px 28.5px;
        width: fit-content;
        display: block;
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
