@import 'variables.scss';
.home {
  font-family: "Poppins", sans-serif;

  .homeSubtitle {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #4f5063;
    text-transform: uppercase;
  }
  .homeTitle {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 45px;
  }

  .homeSubtitle,
  .homeTitle {
    @media (max-width: 575px){
      text-align: start;
      align-self: flex-start;
    }
  }
  .homeText {
    font-size: 18px;
    line-height: 1.83;
    color: #050713;
    text-align: center;
  }
  .banner {
    width: 100%;
    margin: 50px 0;
  }

  :global(.slick-dots) {
    li button {
      &:before {
        font-size: 13px;
      }
    }
  }
}
