@import 'variables.scss';

.modalContainer {
  .modalHeader {
    border-bottom: none;
  }
}
.modalWrapper {
  margin: 5%;
  .colorPaletteContentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    @include tablets {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
    .colorsContainer {
      width: 100%;
    }
    .logoUploadContainer {
      @include tablets {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
      }
      @include sm {
        flex-direction: column;
      }
    }
    .colorSelectedContainer {
      display: flex;
      flex-direction: column;
      @include tablets {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}

.navigationInfo {
  display: flex;
  flex-direction: row;
  font-size: 15.7px;
  font-weight: 600;
  padding-top: 30px;
  padding-bottom: 15px;
}
.labelMedium {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: $dark;
}
.labelSmall {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: $dark;
  margin-top: 20px;
  @include tablets {
    margin-top: 0px;
  }
}

.selector {
  font-size: 16px;
  color: $dark;
  :global {
    width: 250px;
    @include sm {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .ant-select-selector {
      z-index: 1;
      background: transparent !important;
    }
    .ant-select-arrow {
      z-index: 0;
    }
  }
}

.modalContainerStyle {
  min-width: 75% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 70%;
  @include sm {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    button {
      &:last-child {
        margin-top: 20px;
      }
    }
  }
}
.uploadLogoValidationTitle {
  color: red;
  display: flex;
  justify-content: flex-end;
}
.uploadLogo {
  position: relative;
  width: 250px;
  height: 130px;
  :global {
    .ant-upload-picture-card-wrapper {
      display: block;
      .ant-upload {
        width: -webkit-fill-available;
        border-radius: 4px;
        box-shadow: inset 5px 5px 10px 0 #a6abbd, inset -5px -5px 10px 0 #fafbff;
        border: solid 1px rgba(255, 255, 255, 0.4);
        background-image: linear-gradient(293deg, #000000, var(--white) -1%),
          linear-gradient(to bottom, #ebecf0, #ebecf0);
        margin-right: 0px;
        margin-bottom: 0px;
        .img-thumbnail {
          object-fit: contain;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  @include sm {
    margin-left: auto;
    margin-right: auto;
  }
}
.mainBorder {
  color: red;
}

.colorItemsContainer {
  width: 450px;
  @include tablets {
    width: 100%;
  }
}
