@import "../../variables";

.subscriptionEndingBanner {
  height: 100px;
  background-color: $grapefruit;

  .bannerContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .bannerContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      p {
        color: white;
        text-align: center;
        font-weight: 600;
        margin-bottom: 0;

        :first-child {
          margin-bottom: 10px;
        }
      };
    }
  }
}
