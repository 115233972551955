@import 'variables.scss';
.tabsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include sm {
    display: flex;
    flex-direction: column;
  }
  @include tablets {
    display: flex;
    flex-direction: column;
  }
}

.numberOfViews {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-left: 10px;
  color: $white;
}
.tabIcon {
  padding-right: 12px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @include sm {
    padding: 0;
  }
  @include tablets {
    padding: 0;
  }
}
.tabText {
  color: $white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  @include sm {
    display: none;
  }
  @include tablets {
    display: none;
  }
}
