@import 'variables.scss';
:global {
  @include sm {
    div#email {
      order: -1;
    }
    div#password {
      order: -1;
    }
  }
}
.register {
  font-family: 'Poppins', sans-serif;

  .navigationInfo {
    display: flex;
    flex-direction: row;
    font-size: 15.7px;
    font-weight: 600;
    @include sm {
      display: none;
    }
    h5 {
      color: $dark;
      text-transform: uppercase;
      &:hover {
        filter: invert(50%);
      }
    }
    h6 {
      line-height: inherit;
      color: $dark;
    }
  }
  .headlineRegister {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 1px;
    text-align: center;
    color: $dark;
    @include sm {
      display: none;
    }
  }
  .titleRegister {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: 0.5px;
    text-align: center;
    color: $dark;
    margin: 0px;
    @include sm {
      display: none;
    }
  }
  .mobileTitleRegister {
    display: none;
    @include sm {
      display: block;
      font-size: 20px;
      font-weight: 500;
      text-align: left;
      color: #4f5063;
      text-transform: uppercase;
    }
  }
  .inputsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .countryInputStyle {
      :global {
        .ant-select {
          width: 100%;
          padding: 0px 15px;
          &:focus {
            outline: none;
            border: none;
          }
          .ant-select-selector {
            border: none;
            background: transparent;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            padding: 0px;
            box-shadow: none;
            .ant-select-selection-search {
              left: 0;
              color: $dark;
            }
            .ant-select-selection-placeholder {
              color: $dark;
              opacity: 0.6;
            }
          }
          &:focus {
            outline: none;
            border: none;
          }
        }
      }
    }
    @include sm {
      padding: 0px 20px;
      #email {
        order: 1;
      }
      #password {
        order: 2;
      }
      #country {
        order: 3;
      }
      #company {
        order: 4;
      }
    }
    .checkoxFormGroup {
      display: flex;
      flex-direction: row;
      padding: 0px;
      .checkBoxContainer {
        display: flex;
        flex-direction: row;
        .formControl {
          :global {
            .ant-checkbox {
              .ant-checkbox-inner {
                height: 24px;
                width: 24px;
                border: 1.5px solid $dark;
                border-radius: 4px;
              }
            }
            .ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: transparent;
                &::after {
                  border-color: $dark;
                  top: 48%;
                  left: 4px;
                  width: 8px;
                  height: 16px;
                }
              }
            }
          }
          &.validationErrorStyle {
            :global {
              .ant-checkbox {
                .ant-checkbox-inner {
                  border: 1.5px solid red;
                }
              }
            }
          }
        }
      }
      h4 {
        font-size: 15px;
        line-height: 1.47;
        a {
          color: $dark;
        }
      }
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 70%;
    @include tablets {
      width: 80%;
      margin-right: 80px;
    }
    @include sm {
      width: 100%;
    }
    .mainButtonStyle {
      width: 30%;
      height: 50px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        color: $dark;
      }
    }
    .buttonSuccessStyle {
      width: 50%;
      height: 50px;
      padding: 0px;
      &:hover:disabled {
        opacity: 0.5;
        background-color: $dark;
        color: $white;
      }
    }
    a {
      width: 20%;
    }
    @include sm {
      padding-top: 3%;
      .mainButtonStyle {
        padding: 10px;
        width: 32%;
        font-size: 13px;
      }
      .buttonSuccessStyle {
        width: 63%;
        margin-right: 5px;
      }
      a {
        width: 35%;
        margin-right: 20px;
      }
    }
  }
  .error {
    top: -40%;
    position: absolute;
    right: 0px;
    color: red;
    font-size: 12px;
    font-weight: 500;
  }
  .checkboxErrorStyle {
    top: -20%;
  }
}
