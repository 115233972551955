@import "variables.scss";

.commentsMainContainer {
  font-family: "Poppins", sans-serif;

  .commentsList {
    margin-top: 30px;
  }

  .numberOfComments {
    max-width: 741px;
    width: 100%;
    margin-top: 48px;
    margin-bottom: 0px;
    font-size: 24px;
    padding-bottom: 19px;
    border-bottom: 1px solid $steel-42;
  }

  .showAll {
    padding: 0;
    margin-top: 34px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
    border: none;
    outline: none;
    background-color: transparent;
  }
}
