@import 'variables.scss';
.rateContainer {
  font-family: 'Poppins', sans-serif;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.13);
  background: $white;
  padding: 15px 20px;
  width: 300px;
  @include sm {
    margin-bottom: 20px;
  }
  h3 {
    font-size: 12px;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .customButtonStyles {
    height: 50px;
    margin-top: 15px;
    width: 100%;
    &:disabled {
      &:hover {
        background: $steel-42;
      }
    }
  }
  .ratingSlider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0px;
      color: $dark;
    }
  }
  .ratingDoneContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .checkOutlinedStyle {
      color: $success-color;
      border-radius: 50%;
      border: 4px solid $dark;
      font-size: 30px;
      padding: 12px;
    }
    h3 {
      font-size: 16px;
      font-weight: normal;
      text-align: center;
      width: 80%;
    }
  }
}
