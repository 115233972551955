@import 'variables.scss';
.slideContainer {
  background: rgba(0, 0, 0, 0.9) no-repeat;
  height: 445px;
  background-size: cover;
  width: 100%;
  z-index: 3;
  overflow: hidden;

  :global(.slick-prev),
  :global(.slick-next) {
    z-index: 1;
    &::before {
      content: none;
    }
  }

  :global(.slick-dots) {
    bottom: 10px;
    li button::before {
      color: $white;
    }
  }

  :global(.slick-prev) {
    left: 25px;
  }

  :global(.slick-next) {
    right: 65px
  }

  :global(.slick-slide) {
    position: relative;
    overflow: hidden;
  }

  .slideContent {
    height: 445px;
    display: flex !important;
    flex-direction: row;
    align-items: center;

    .productContent {
      margin-left: 235px;
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      z-index: 5;

      @media (max-width: 1119px) {
        margin-left: 150px;
      }

      @include tablets {
        margin-left: 75px;
        width: 65%;
      }

      @media (max-width: 575px) {
        margin-left: 15px;
        width: 100%;
      }

      .category {
        opacity: 0.43;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 2.22;
        letter-spacing: -0.25px;
        color: $white;
      }

      .title {
        line-height: 1.33;
        max-height: 144px;
        overflow: hidden;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: -0.56px;
        color: $white;
      }

      .validTo {
        opacity: 0.55;
        line-height: 2.22;
        color: $white;
        font-size: 18px;
      }

      .descriptionButton {
        width: 70%;
        height: 60px;
        border-radius: 6px;
        border: none;
        text-transform: uppercase;
        font-size: 15px;
        color: #000;
        text-align: center;
        background: $success-color;
      }
    }

    .productContainer {
      border-radius: 50%;
      border: 35px solid $success-color;
      position: absolute;
      bottom: -150px;
      right: -150px;
      width: 625px;
      height: 625px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .overlay {
        display: none;
      }

      @media (max-width: 575px){
        border: none;
        width: 100%;
        height: 445px;
        border-radius: 0;
        right: 0;
        bottom: 0;
        img {
          object-fit: cover;
          border-radius: 0;
          height: 445px;
          width: 100%;
        }
        .overlay {
          background-color: rgba(0, 0, 0, 0.5);
          height: 100%;
          width: 100%;
          position: absolute;
          z-index: 1;
          display: block;
        }
      }
    }
  }
}
