@import 'variables.scss';
.easyStepsContainer {
  margin-top: 87px;
  @include sm {
    margin-top: 30px;
  }
  .easyStepsHeadingContainer{
    @include sm {
      margin-left: 15px;
      h6 {
        text-align: left;
      }
      h5 {
        text-align: left;
      }
    }
  }

  .subtitle {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #4f5063;
    text-transform: uppercase;
  }
  .title {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 45px;
  }

  .stepsRow {
    margin-top: 40px;
    min-height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include sm {
      margin-top: 0px;
    }
    .stepTitleContainer {
      width: 90%;
    }
    .stepSequenceNumber {
      width: 25px;
      height: 25px;
      background-color: #000;
      border-radius: 50%;
      color: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      font-size: 16px;
      vertical-align: super;
      @include sm {
        width: 28px;
        height: 28px;
      }
    }
    .stepTitle {
      display: inline;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.5;
      color: #050713;
      min-height: auto;
      @include sm {
        margin: 0;
        font-size: 20px;
        line-height: 1.8;
      }
    }
    .stepText {
      font-size: 15px;
      line-height: 1.73;
      color: #050713;
    }
    .stepBreakLine {
      border: 2px solid black;
      width: 40px;
      margin-top: 20px;
      margin-bottom: 22px;
    }
    img {
      height: 70px;
    }
  }
}
