@import 'variables.scss';
.contactInfoMainContainer {
  font-family: 'Poppins', sans-serif;
  .contactInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .inputFieldsContainer {
      display: flex;
      flex-direction: row;
      margin-bottom: 72px;
      width: 100%;
      @include sm {
        flex-direction: column-reverse;
        align-items: center;
      }
      .imagesContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        .mapImgStyle {
          width: 80%;
          align-self: center;
        }
        .mapMarkerImgStyle {
          position: absolute;
          width: 25px;
          padding-top: 10%;
        }
        h5 {
          position: absolute;
          padding-top: 19%;
          font-size: 16px;
          line-height: 1.75;
          color: $white;
        }
      }
      .inputMain {
        width: 45%;
        @include sm {
          margin-top: 30px;
          width: 90%;
        }
      }
    }
  }
  h4 {
    font-size: 18px;
    font-weight: 500;
    padding-top: 60px;
    margin-bottom: 10px;
  }
}
.contactInfo {
  font-family: 'Poppins', sans-serif;
  h5 {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    margin-bottom: 3px;
    text-transform: capitalize;
  }
  h4 {
    padding-top: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    margin-bottom: 13px;
  }
  .contactInfoLink {
    padding-top: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    margin-bottom: 13px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
  }
}
.simpleMapContainer {
  height: 400px;
  width: 100%;
}
