.socialShare{
    text-align:right;
    margin-left:auto;
    padding: 0 20px 0 0;
  margin-top: 15px;
    button{
      margin-right:15px !important;
      &:focus{
        outline: none;
      }
    }
  }

  .clearShareBtn {
    border: none;
    background: transparent;
  }