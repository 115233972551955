@import 'variables.scss';
.mainButtonStyle {
  padding: 20px 49px;
  border: solid 2px $dark;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: $dark;
  text-transform: uppercase;
  background: $white;
  &:hover {
    opacity: 0.6;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
  @include sm {
    width: 100%;
  }
}
.buttonSuccessStyle {
  padding: 20px 60px;
  border: none;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: $white;
  text-transform: uppercase;
  background: $dark;
  display: flex;
  align-items: center;
  justify-content: center;
  @include sm {
    width: 100%;
    border-radius: 6px;
    font-size: 13px;
    letter-spacing: 0.3px;
  }
  &:hover {
    background-color: $success-color;
    color: $dark;
  }
  &:focus {
    outline: none;
  }
}
