@import 'variables.scss';
.productsAndServicesMainContainer {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 60px;
  h4 {
    font-size: 18px;
    font-weight: 500;
    padding-top: 75px;
    margin-bottom: 25px;
  }
  h3 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 62px;
  }
  .productsAndServicesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .productItemContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    border-radius: 6px;
    box-shadow: 5px 5px 10px 0 #a6abbd;
    height: 100%;
    @include sm {
      margin-bottom: 15px;
    }
    .imageContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 17px;
      margin-bottom: 17px;
      .addIconImgStyle {
        width: 80px;
        object-fit: contain;
      }
      .buttonSuccessStyle {
        height: 45px;
        width: 170px;
        line-height: 0px;
        padding: 0;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.28px;
      }
      .svgIconContainer {
        height: 80px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .inputSectionTitle {
      width: 100%;
      background: transparent;
      color: $dark;
      border-radius: 4px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 600;
      line-height: 2;
      padding-left: 10px;
      padding-right: 10px;
      @include tablets {
        line-height: normal;
        font-size: 14px;
        padding: 5px 0px; 
      }
    }
    .horizontalBreakline {
      border: 1px solid #191924;
      background: black;
      width: 100%;
      margin-bottom: 20px;
    }
    .inputSectionDescription {
      word-wrap: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
      width: 100%;
      background: transparent;
      color: $dark;
      border-radius: 4px;
      resize: none;
      font-size: 14px;
      line-height: 1.5;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 42px;
      height: 159px;
      @include tablets {
        font-size: 11px;
      }
    }
  }
}
