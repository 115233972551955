@import 'variables.scss';
.testimonials {
  font-family: 'Poppins', sans-serif;
  background-color: $success-color;
  @include tablets {
    height: 90vh;
  }

  .navigationInfo {
    display: flex;
    flex-direction: row;
    font-size: 15.7px;
    font-weight: 600;
    @include sm {
      display: none;
    }
    h5 {
      color: $dark;
      text-transform: uppercase;
      &:hover {
        filter: invert(50%);
      }
    }
    h6 {
      line-height: inherit;
      color: $dark;
    }
  }
  .testimonialsContainer {
    height: 70vh;
    width: 70%;
    background-color: $white;
    border-radius: 15px;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.21);
    padding-top: 15px;
    padding-bottom: 15px;
    @include tablets {
      width: 90%;
    }
    @include sm {
      width: 100%;
    }
    .scrollContainer {
      height: 100%;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
        padding-top: 15px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #d8d8d8;
        border-right: 4px white solid;
      }
      .testimonialItem {
        .bubbleContainer {
          width: 90%;
          position: relative;
          border-radius: 0.4em;
          padding: 20px;
          margin-bottom: 20px;
          box-shadow: 5px 5px 10px 0 #a6abbd, -5px -5px 10px 0 #fafbff;
          p {
            font-size: 14px;
            line-height: 1.43;
          }
          @include sm {
            width: 90%;
            p {
              font-size: 12px;
            }
          }
        }
        .bubbleContainer:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 40px;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-top-color: $dark;
          border-bottom: 0;
          margin-left: -25px;
          margin-bottom: -10px;
        }
        &:nth-child(odd) {
          .bubbleContainer {
            background: $dark;
            color: $white;
          }
        }
        &:nth-child(even) {
          .bubbleContainer {
            background: $white;
            color: $dark;
            box-shadow: inset 5px 5px 10px 0 #a6abbd,
              inset -5px -5px 10px 0 #fafbff;
            border: solid 1px rgba(255, 255, 255, 0.4);
            background-color: #ebecf0;
          }
          .bubbleContainer:after {
            border-top-color: $dark;
            opacity: 0.1;
          }
        }
        .testimonialInfoRow {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          margin-left: 6%;
          h5 {
            font-size: 14px;
            font-weight: 600;
            color: $dark;
          }
          h6 {
            font-size: 12px;
            color: $dark;
          }
          @include sm {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 6%;
            img {
              display: none;
            }
          }
        }
      }
    }
  }
  .bizbookMobileAdSection {
    display: flex;
    flex-direction: column;
    background: $dark;
    text-align: center;
    color: $white;
    padding-top: 120px;
    padding-bottom: 120px;
    @include sm;
    @include tablets {
      display: none;
    }
    img {
      align-self: center;
      &:first-child {
        width: 10%;
        object-fit: contain;
      }
      &:last-child {
        width: 4%;
        padding-top: 35px;
      }
    }
    h3 {
      font-size: 32px;
      font-weight: 600;
      padding: 20px 0px;
      color: $white;
    }
    h4 {
      padding-top: 35px;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      color: $white;
    }
  }
}
