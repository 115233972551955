@import 'variables.scss';
.aboutUsMainContainer {
  height: auto;
}
.aboutUsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  h4 {
    font-size: 18px;
    font-weight: 500;
    color: #4f5063;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .inputSectionTitle {
    width: 100%;
    background: transparent;
    color: $dark;
    border: 1.5px dashed $grey;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 700;
    @include sm {
      font-size: 24px;
    }
  }
  .inputSectionDescriptionContainer {
    width: 40%;
  }
  .inputSectionDescription {
    width: 100%;
    background: transparent;
    color: $dark;
    border: 1.5px dashed $grey;
    border-radius: 4px;
    overflow: hidden;
    resize: none;
    font-size: 14px;
    line-height: 1.5;
    overflow-y: auto;
    font-family: 'Poppins', sans-serif;
  }
  .noBorderStyle {
    border-color: transparent;
  }
  .svgIconContainer {
    height: 140px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 43px;
    margin-bottom: 12px;
  }
  .viewModeTextStyle {
    border: none;
  }
  .shortDecriptionTextViewMode {
    border: none;
    width: 60%;
    @include tablets {
      width: 80%;
    }
    @include sm {
      width: 90%;
    }
  }
  .textCounterContainer {
    margin-bottom: 0px;
    text-align: right;
    font-size: 12px;
    transform: translate(0px, 3px);
  }
  .aboutUsTextStyle {
    padding-left: 10px;
    padding-right: 10px;
  }
}
