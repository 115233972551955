@import 'variables.scss';
.resetPasswordContainer {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 90px;
    object-fit: contain;
  }
  h1 {
    font-size: 28px;
    line-height: 1.32;
    letter-spacing: 0.1px;
  }
  h2 {
    font-size: 16px;
    letter-spacing: 0.06px;
  }
  .formStyle {
    width: 50%;
    @include sm {
      width: 100%;
    }
  }
}
