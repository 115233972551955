@import 'variables.scss';

.categoryBanner {
  margin-bottom: 33px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .categoryTag {
    width: 149px;
    height: 38px;
    border-radius: 19px;
    border: solid 1px #050713;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    background: $white;

    &:last-child {
      margin-right: 0;
    }

    span {
      font-weight: bold;
      font-size: 11px;
      letter-spacing: 0.7px;
      text-align: center;
      color: #03050e;
      text-transform: uppercase;
    }
  }

  @media (max-width: 991px) {
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-between;
    width: 479px;
    height: 88px;
    align-self: center;

    .categoryTag {
      margin: 0;
    }
  }

  @media (max-width: 575px) {
    margin-top: 33px;
    height: 136px;
    width: 100%;
  }

  @media (max-width: 325px) {
    height: 280px;
  }

  .selected,
  .categoryTag:hover,
  .categoryTag:active,
  .categoryTag:focus {
    --antd-wave-shadow-color: none;
    border-color: $success-color;
    background: $success-color;
  }
}
