@import 'variables.scss';
.uploadedImageContainer {
  flex: 0 0 auto;
  height: 200px;
  width: 200px;
  position: relative;
  margin-right: 15px;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 15px;
  }
  .iconStyle {
    position: absolute;
    right: 5%;
    top: 5%;
    font-size: 16px;
    background: $dark;
    color: $white;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
  }
}
