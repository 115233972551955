@import 'variables.scss';
.selectContainer {
  width: 100%;
  font-size: 13px;
  background: transparent;
  color: $white;
  border: none;
  outline: none;
  text-transform: uppercase;
  .optionStyle {
    background: $dark;
  }
  &:focus {
      outline: none;
  }
}
.optionItem{
  display: flex;
  flex-direction: row;
  justify-content: center;
  img {
    width: 15px;
    object-fit: contain;
  }
  h5 {
    font-size: 14px;
    padding-top: 9px;
    padding-left: 5px;
    color: $white;
    text-transform: uppercase;
  }
}
.selectStyle {
  color: $white;
  div {
    border: none;
    background: $dark;
    border-radius: 4px;
    &:focus {
      outline: none;
    }
  }
}
