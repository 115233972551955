@import 'variables.scss';
.aboutCompanyContainer {
  position: relative;
  min-height: 100vh;
}
.navigationContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
  font-family: 'Poppins', sans-serif;
  @include sm {
    flex-direction: column;
    align-items: center;
  }
  .navigationInfo {
    display: flex;
    flex-direction: row;
    font-size: 15.7px;
    font-weight: 600;
    @include sm {
      width: 100%;
    }
    h5 {
      color: $dark;
      text-transform: uppercase;
      &:hover {
        filter: invert(50%);
      }
      cursor: pointer;
    }
    h6 {
      line-height: inherit;
      color: $dark;
    }
  }
  .progressBar {
    display: inline-block;
    @include sm {
      display: none;
    }
    @include tablets {
      display: none;
    }
  }
  .siteWatchingModeContainer {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 347px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.13);
    background-color: $white;
    padding-bottom: 20px;
    h4 {
      font-size: 12px;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 15px;
    }
    .buttonContainer {
      display: flex;
      justify-content: space-around;
      align-items: center;
      button {
        text-transform: uppercase;
        border-radius: 6px;
        background: $dark;
        color: $white;
        width: 140px;
        height: 40px;
        border: none;
        &:focus {
          outline: none;
        }
      }
      .iconStyle {
        font-size: 10px;
        color: $success-color;
        vertical-align: middle;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
.sectionsContainer {
  width: 55%;
  min-height: 100vh;
  @include tablets {
    width: 100%;
  }
}
.editModeSectionContainer {
  .companyInfoSectionContianer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 60px;
    h6 {
      font-size: 16px;
      text-align: center;
      font-weight: normal;
      color: $grey;
    }
    .profileRatingContianer {
      padding-right: 30px;
      h3 {
        font-size: 38px;
        font-weight: 600;
        letter-spacing: -0.5px;
        transform: translate(0px, 20%);
      }
    }
    .employeeNumberContianer {
      .inputEmployeeCount {
        width: 125px;
        color: $dark;
        border: 1.5px dashed black;
        border-radius: 4px;
        margin-bottom: 20px;
        background: $white;
        font-size: 38px;
        font-weight: 600;
        letter-spacing: -0.5px;
        text-align: center;
        &::-webkit-input-placeholder {
          color: $dark;
          opacity: 0.7;
        }
        &:focus {
          outline: none;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }
  .moreAboutCompanyText {
    font-size: 18px;
    font-weight: 500;
    color: $grey;
  }
}
.inputContainer {
  width: 100%;
  color: $dark;
  border: 1.5px dashed black;
  border-radius: 4px;
  margin-bottom: 20px;
  background: $white;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: -0.5px;
  text-align: left;
  overflow: hidden;
  resize: none;
  min-height: 70px;
  &::-webkit-input-placeholder {
    color: $dark;
    opacity: 0.7;
  }
  &:focus {
    outline: none;
  }
}
.inputDescription {
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  resize: none;
  line-height: 1.5;
}
.inputShortDescription {
  min-height: 130px;
}
.addPictureButtonStyle {
  height: 50px;
  width: 240px;
  border-radius: 27.5px;
  border: 1.5px dashed rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 0;
  background: rgba(0, 0, 0, 0.6);
  color: $white;
  &:focus {
    outline: none;
  }
  img {
    height: 25px;
    object-fit: contain;
  }
}
.imageUploadContainer {
  position: relative;
  .mainImageStyle {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .rowImageStyle {
    height: 220px;
  }
  .loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 220px;
    .loaderImgStyle {
      width: 20%;
      object-fit: contain;
    }
    :global {
      .lazy-load-image-background {
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .imageUploadButtonContainer {
    height: 70px;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    :global {
      .ant-upload {
        background: transparent;
        border: none;
        margin: 0;
        width: 100%;
      }
    }
  }
}
.imagesContainer {
  display: flex;
  flex-direction: row;
  @include sm {
    flex-direction: column;
    align-items: center;
  }
}
.saveChangesButtonContainer {
  margin-top: 46px;
  margin-bottom: 110px;
}
.customFooterContainer {
  background: $dark;
  height: 120px;
  display: flex;
  align-items: center;
  @include sm {
    height: 60px;
  }
  .customFooterContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .companyLogoStyle {
      width: 110px;
      height: 50px;
      object-fit: contain;
    }
    .socialLinksContainer {
      display: flex;
      align-items: center;
      .socialLinksContent {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
      img {
        height: 20px;
        object-fit: contain;
        padding-left: 30px;
        align-self: center;
        &:hover {
          opacity: 0.7;
        }
      }

      .followUsText {
        font-size: 16px;
        text-transform: uppercase;
        color: white;
        margin-bottom: 0;
        @include sm {
          display: none;
        }
      }
    }
  }
}
.checkOutlinedContainer {
  height: 45px;
  width: 163px;
  background: $success-color;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  .iconStyle {
    font-size: 23px;
    font-weight: bold;
    color: $white;
  }
}
