@import 'variables.scss';
.myFeedContent {
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  .noProductsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    h6 {
      font-size: 24px;
    }
  }
  .jumpTopTop {
    width: 41px;
    object-fit: contain;
    cursor: pointer;
    position: fixed;
    right: 5%;
    bottom: 55px;
    z-index: 2;
  }
  .imageloaderStyle {
    margin-left: 50%;
    transform: translate(-50%, 0%);
    width: 120px;
  }
}
.myFeedControlContainer {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 60px;
  padding-top: 40px;
  @include sm {
    flex-wrap: wrap-reverse;
    min-height: 120px;
  }
  .viewTypeContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include sm {
      width: 100%;
      justify-content: center;
      margin-top: 20px;
    }
    button {
      text-transform: uppercase;
      font-size: 17px;
      font-weight: 600;
      color: $dark;
      cursor: pointer;
      border: none;
      border-radius: 25px;
      background: $white;
      height: 50px;
      padding-left: 20px;
      padding-right: 20px;
      &:focus {
        outline: none;
      }
      @include sm {
        font-size: 16px;
      }
    }
    .activeButton {
      box-shadow: 5px 5px 10px 0 #a6abbd;
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include sm {
      width: 100%;
      justify-content: center;
      margin-top: 10px;
    }
    .buttonSuccessStyle {
      height: 50px;
      padding: 0;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      .plusIconStyle {
        margin-left: 10px;
      }
    }
  }
}
.mainTitleStyle {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-weight: 600;
  color: $dark;
  margin-top: 37px;
  margin-bottom: 40px;
  @include sm {
    display: none;
  }
}
