@import 'variables.scss';
.adminPanelMainContainer {
  font-family: 'Poppins', sans-serif;
  min-height: 100vh;

  .adminActionTabContainer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    @include sm {
      flex-direction: column-reverse;
      margin: 10px 0;
      align-items: flex-end;
    }
    @include tablets {
      flex-direction: column-reverse;
      margin: 10px 0;
      align-items: flex-end;
    }
    .buttonsContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @include sm {
        justify-content: flex-end;
      }
      @include tablets {
        justify-content: flex-end;
      }
    }
  }
  .selectStyle {
    :global {
      .ant-select-selector {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .industryField {
    width: 175px;
  }

  .tableContainer {
    :global {
      .ant-spin-nested-loading .ant-spin-container {
        .ant-table .ant-table-content table tbody tr td {
          font-size: 15px;
        }
        .ant-pagination {
          .ant-pagination-prev,
          .ant-pagination-next {
            a {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .activeRowStyle {
    background-color: #bee5eb;
  }

  .paginationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    .paginationNumbersRow {
      display: flex;
      max-width: 80%;
      overflow-x: auto;
      align-items: center;
      .page {
        border: 1.5px solid black;
        border-radius: 3px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 5px;
        flex-shrink: 0;
        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }
        p {
          font-family: Arial;
          font-weight: 500;
          margin: 0;
        }
      }
      .activePage {
        border-color: $success-color;
        background-color: $success-color;
      }
    }
    h5 {
      margin: 0;
      font-weight: 500;
      font-size: 15px;
      &:hover {
        color: $success-color;
        cursor: pointer;
      }
    }
  }
  .companyNameText {
    cursor: pointer;
  }

  .statisticContainer {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    @include sm {
      button {
        width: fit-content;
        margin:auto;
        display: block;
      }
    }
  }
}
