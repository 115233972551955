$dark: #191924;
$grapefruit: #f95858;
$very-light-blue-0: rgba(235, 236, 240, 0);
$light-blue-grey: #c9ccd6;
$steel-42: rgba(119, 119, 138, 0.42);
$charcoal-grey: #464651;
$slate: #45455e;
$dark-two: #212436;
$white: #ffffff;
$success-color: #37ead9;
$grey: #4f5063;
$green-light: #30cdc0;
$light-grey: #cecece;
$dark-three: #050713;
$not-included-gray: #a5a5a5;
$darker-white: #f5f5f5;

$package-item-border: 1px solid $light-grey;

:global {
  .ant-notification {
    z-index: 1060;
  }
  .antd-img-crop-modal {
    z-index: 1055;
  }
}

// mobile constants
$mobile-navbar-height: 66px;

// Extra small devices (phones, 600px and down)
$screen-sm-max: 600px;
$screen-md-min: 768px;
$desktop-width: 1024px;

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// tablets
@mixin tablets {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

// tablets landscape
@mixin tabletsLandscape {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}
