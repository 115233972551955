@import 'variables.scss';
.companyHeaderContainer {
  font-family: 'Poppins', sans-serif;
  padding-bottom: 60px;
  background: $dark;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
  position: relative;
  min-height: 80vh;
  @include tablets {
    min-height: 60vh;
  }
  @include sm {
    min-height: 50vh;
  }
  .editActionsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include sm {
      flex-direction: column;
      align-items: center;
    }
    .mainButtonStyle {
      height: 60px;
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      .messageIconStyle {
        font-size: 25px;
        margin-right: 15px;
      }
    }
    .modalButtonContainer {
      display: flex;
      @include tablets {
        flex-direction: column;
      }
      @include sm {
        flex-direction: column;
        align-items: center;
      }
    }
    .siteWatchingModeContainer {
      display: flex;
      flex-direction: column;
      background: $white;
      border-radius: 10px;
      h4 {
        font-size: 12px;
        text-align: center;
        margin-top: 25px;
        margin-bottom: 15px;
      }
      .buttonContainer {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 350px;
        border-radius: 10px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.13);
        background-color: $white;
        padding-bottom: 20px;
        button {
          text-transform: uppercase;
          border-radius: 6px;
          background: $dark;
          color: $white;
          width: 140px;
          height: 40px;
          border: none;
          &:focus {
            outline: none;
          }
        }
        .iconStyle {
          font-size: 10px;
          color: $success-color;
          vertical-align: middle;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .iconStyle {
      font-size: 10px;
      color: $success-color;
      vertical-align: middle;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .companyInfoHeader {
    padding-top: 60px;
    .inputCompanySlogan {
      width: 100%;
      font-size: 48px;
      font-weight: 700;
      line-height: 1.33;
      background: rgba(0, 0, 0, 0.5);
      color: $white;
      border: 1.5px dashed rgba(255, 255, 255, 0.7);
      border-radius: 4px;
      resize: none;
      @include sm {
        font-size: 28px;
      }
    }
    .inputAboutCompany {
      width: 100%;
      font-size: 18px;
      line-height: 1.8;
      background: rgba(0, 0, 0, 0.5);
      color: $white;
      border: 1.5px dashed rgba(255, 255, 255, 0.7);
      border-radius: 4px;
      margin-bottom: 20px;
      @include sm {
        font-size: 14px;
      }
    }
    .aboutCompanyButtonStyle {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 300px;
      border: solid 2px #050713;
      border-radius: 6px;
      background: $white;
      margin-bottom: 46px;
      color: $dark;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.37px;
      @include sm {
        width: 150px;
        height: 45px;
        padding: 0;
        font-size: 14px;
      }
      &:hover {
        background-color: $success-color;
        color: $dark;
      }
    }
    .noBorderStyle {
      border-color: transparent;
      background: transparent;
    }
    .viewModeTextStyle {
      border: none;
      background: transparent;
    }
    .aboutCompanyViewModeTextStyle {
      border: none;
      background: transparent;
      font-size: 19px;
      font-weight: normal;
    }
  }
  .companyHeaderContentContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .lazyLoadImageContainer {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.4 !important;
    position: relative;
  }
  :global {
    .lazy-load-image-background {
      position: absolute;
      height: 100%;
      top: 0;
      width: 100%;
      object-fit: cover;
    }
  }
  .headerRightContainer {
    display: flex;
    flex-direction: column;
    min-height: 110px;
  }
}
