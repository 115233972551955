@import 'variables.scss';
.compose {
  display: flex;
  align-items: center;
  background: white;
  border-top: 1px solid #eeeef1;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 10px;
}

@supports (backdrop-filter: blur(20px)) {
  .compose {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.compose-input {
  flex: 1;
  border: none;
  font-size: 14px;
  height: 40px;
  background: white;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  &:focus {
    border: 2px solid #007aff;
    outline: none;
  }
}

.compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}

.compose .send-outlined-style {
  font-size: 23px;
  color: #007aff;
  cursor: pointer;
}