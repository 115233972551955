@import 'variables.scss';
.interestsContainer {
  font-family: 'Poppins', sans-serif;
  min-height: calc(100vh - 72px);
  padding-top: 40px;
  @include sm {
    min-height: calc(100vh - 51px);
  }
  .navigationInfo {
    display: flex;
    flex-direction: row;
    font-size: 15.7px;
    font-weight: 600;
    @include sm {
      width: 100%;
    }
    h5 {
      color: $dark;
      text-transform: uppercase;
      &:hover {
        filter: invert(50%);
      }
      cursor: pointer;
    }
    h6 {
      line-height: inherit;
      color: $dark;
    }
  }
  h2 {
    font-size: 32px;
    line-height: 1.25;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 50px;
    font-weight: bold;
  }
  h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: 0.5px;
    text-align: center;
    margin-top: 80px;
    @include sm {
      margin-top: 40px;
    }
  }
  .interestMainContent {
    .rowInputContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @include sm {
        flex-direction: column-reverse;
      }
      .addInterestButtonStyle {
        text-transform: uppercase;
        border: 1.5px solid $dark;
        font-size: 12px;
        font-weight: bold;
        border-radius: 5px;
        padding: 3px 8px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-10px, -50%);
      }
      .checkBoxFormGroup {
        display: flex;
        flex-direction: row;
        padding: 0px;
        @include sm {
          justify-content: center;
          margin-bottom: 10px;
          align-items: center;
        }
        .checkBoxContainer {
          display: flex;
          flex-direction: row;
          @include sm {
            align-items: center;
          }
          .formControl {
            :global {
              .ant-checkbox {
                .ant-checkbox-inner {
                  height: 24px;
                  width: 24px;
                  border: 1.5px solid $dark;
                  border-radius: 4px;
                }
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background-color: transparent;
                  &::after {
                    border-color: $dark;
                    top: 48%;
                    left: 4px;
                    width: 8px;
                    height: 16px;
                  }
                }
              }
            }
          }
        }
        h5 {
          font-size: 15px;
          line-height: 1.47;
          a {
            color: $dark;
          }
          @include sm {
            margin: 0px;
          }
        }
      }
      :global {
        .ant-select {
          width: 100%;
          &:focus {
            outline: none;
            border: none;
          }
          .ant-select-selector {
            border: none;
            background: transparent;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            padding: 0px;
            box-shadow: none;
            .ant-select-selection-search {
              left: 0;
              color: $dark;
            }
            .ant-select-selection-placeholder {
              color: $dark;
              opacity: 0.6;
            }
          }
          &:focus {
            outline: none;
            border: none;
          }
        }
      }
    }
    .savedInterestsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 45px;
      @include sm {
        margin-bottom: 15px;
      }
      .interestItem {
        display: flex;
        align-items: center;
        height: 40px;
        border-radius: 4px;
        border: solid 0.5px #9da3b4;
        margin-right: 8px;
        margin-bottom: 15px;
        cursor: pointer;
        padding-left: 25px;
        padding-right: 10px;
        &:hover {
          background-color: $success-color;
        }
        p {
          font-size: 12px;
          font-weight: 500;
          margin: 0px;
          padding-right: 15px;
          text-transform: uppercase;
        }
      }
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 70%;
    @include tablets {
      width: 80%;
      margin-right: 80px;
    }
    @include sm {
      width: 100%;
    }
    .mainButtonStyle {
      width: 30%;
      height: 50px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        color: $dark;
      }
    }
    .buttonSuccessStyle {
      width: 50%;
      height: 50px;
      padding: 0px;
      &:hover:disabled {
        opacity: 0.5;
        background-color: $dark;
        color: $white;
      }
    }
    a {
      width: 20%;
    }
    @include sm {
      padding-top: 3%;
      .mainButtonStyle {
        padding: 10px;
        width: 32%;
        font-size: 13px;
      }
      .buttonSuccessStyle {
        width: 63%;
        margin-right: 5px;
      }
      a {
        width: 35%;
        margin-right: 20px;
      }
    }
  }
}
