@import "variables.scss";

.articlePreviewContainer {
  width: 67.5%;

  @media (min-width: 768px) and (max-width: 991px) {
    width: 60%;
  }

  @media (max-width: 575px) {
    width: 100%;
  }
}

.article {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }

  .imgContainer,
  .contentContainer {
    width: 48%;
    height: 257px;

    img {
      object-fit: cover;
      width: 100%;
      height: 257px;
    }
  }

  .contentContainer {
    width: 44%;
    margin-right: 4%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    height: 529px;
    width: 95%;
    margin-right: 5%;

    .imgContainer,
    .contentContainer {
      width: 100%;
    }

    .contentContainer {
      margin: 0;
    }
  }

  @media (max-width: 575px) {
    width: 100%;

    .imgContainer,
    .contentContainer {
      width: 100%;
    }
  }
}
