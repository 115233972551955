@import 'variables.scss';
.howOthersRateUsMainContainer {
  font-family: 'Poppins', sans-serif;
  margin-top: 80px;
  padding-bottom: 40px;
  border-bottom: 1.5px solid $steel-42;
  @include sm {
    border: none;
    margin-bottom: 43px;
    padding-bottom: 0px;
  }
  h3 {
    font-size: 32px;
    font-weight: 600;
    @include sm {
      font-size: 16px;
    }
  }
  .howOthersRateUs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 87px;
    @include sm {
      flex-direction: column;
      margin-top: 0px;
    }
    .rateContainer {
      @include sm {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      h4 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 24px;
        @include sm {
          margin-top: 30px;
          font-size: 14px;
        }
      }
      p {
        font-size: 16px;
        font-weight: normal;
        margin-top: 24px;
        @include sm {
          display: none;
        }
      }
    }
  }
}
