@import 'variables.scss';
.itemDetailsContainer {
  font-family: 'Poppins', sans-serif;
  min-height: 100vh;

  .navigationInfo {
    display: flex;
    flex-direction: row;
    font-size: 15.7px;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 60px;
    @include sm {
      flex-wrap: wrap;
    }
    h5 {
      color: $dark;
      text-transform: uppercase;
      &:hover {
        filter: invert(50%);
      }
      cursor: pointer;
    }
    h6 {
      line-height: inherit;
      color: $dark;
    }
  }
  .itemDetailsContentContainer {
    display: flex;
    flex-direction: row;
    @include tablets {
      flex-direction: column;
      align-items: center;
    }
    @include sm {
      flex-direction: column;
    }
    .imageContainer {
      .coverImage {
        width: 100%;
        border-radius: 15px;
        cursor: pointer;
        @include tablets {
          display: none;
        }
      }
      .rowContent {
        position: relative;
        height: 190px;
        @include tablets {
          display: none;
        }
      }
      .rowStyledImages {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        overflow-x: scroll;
        margin-top: 26px;
        margin-bottom: 30px;
        height: 100%;
        &::-webkit-scrollbar {
          display: none; /* Hide scroll bar for Chrome and Safari*/
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        @include tablets {
          display: none;
        }
        img {
          width: 197px;
          height: 100%;
          object-fit: cover;
          border-radius: 15px;
          margin-right: 26px;
          cursor: pointer;
        }
        .iconCustomStyle {
          font-size: 18px;
        }
        .scrollRightButton {
          position: absolute;
          right: 4%;
          bottom: 80px;
          background: $white;
          border-radius: 50%;
          vertical-align: middle;
          border: none;
          box-shadow: 0 0 6px 0 #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 40px;
          &:focus {
            outline: none;
          }
        }
        .scrollLeftButton {
          position: absolute;
          left: 4%;
          bottom: 80px;
          background: $white;
          border-radius: 50%;
          vertical-align: middle;
          border: none;
          box-shadow: 0 0 6px 0 #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 40px;
          &:focus {
            outline: none;
          }
        }
      }
      .carouselContainer {
        display: none;
        @include tablets {
          display: block;
          div {
            height: 300px;
            width: 100%;
            img {
              height: 100%;
              width: 100%;
            }
          }
          .dotsStyle {
            background: red;
          }
          :global {
            .ant-carousel {
              .slick-slider {
                .slick-list {
                  border-radius: 15px;
                }
                .slick-dots {
                  width: auto;
                  li {
                    button::before {
                      opacity: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .itemInfoContainer {
      h2 {
        font-size: 38px;
        font-weight: 600;
        line-height: 1.32;
        margin-bottom: 40px;
        margin-top: 24px;
        @include sm {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
        }
      }
      h3 {
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 0;
        @include sm {
          margin-bottom: 35px;
        }
      }
      h5 {
        font-size: 12px;
        font-weight: normal;
        margin-bottom: 34px;
        @include sm {
          display: none;
        }
      }
      h4 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 24px;
        @include sm {
          font-size: 16px;
        }
      }
      .contactCompanyTitle {
        @include sm {
          display: none;
        }
      }
      .textContainer {
        overflow-y: hidden;
        text-overflow: ellipsis;
        transition: height 0.5s ease-out;
        &.fullText {
          transition: height 0.5s ease-out;
        }
        white-space: pre-line;
      }
      .readMoreText {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.86;
        text-decoration: underline;
        cursor: pointer;
        width: fit-content;
      }
      .mainButtonStyle {
        height: 60px;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        .messageIconStyle {
          font-size: 25px;
          margin-right: 15px;
        }
      }
    }
  }
  h3 {
    font-size: 32px;
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 40px;
    @include sm {
      font-size: 16px;
    }
  }
  .moreProductsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @include sm {
      flex-wrap: nowrap;
      overflow-x: scroll;
      margin-bottom: 30px;
    }
    .moreProductsItem {
      margin-bottom: 40px;
      width: 48%;
      height: 400px;
      position: relative;

      @include tablets {
        height: 250px
      }
      @include sm {
        min-width: 156px;
        height: 124px;
        margin-right: 15px;
        margin-bottom: 20px;
      }

      .productImageContainer {
        width: 100%;
        height: 100%;

        .overlay {
          background: rgba(0, 0, 0, 0.3);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          border-radius: 15px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 15px;
        }
      }

      .itemTextContainer {
        position: absolute;
        bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @include sm {
          justify-content: flex-end;
          bottom: 10px;
        }
        width: 100%;
        p {
          font-size: 20px;
          font-weight: 600;
          color: $white;
          margin: 0;
          @include tablets {
            font-size: 12px;
          }
          @include sm {
            display: none;
          }
        }
        .moreDetailsProductButton {
          background: $white;
          border-radius: 50%;
          border: none;
          box-shadow: 0 0 6px 0 #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          width: 40px;
          flex-shrink: 0;
          &:focus {
            outline: none;
          }
          @include sm {
            height: 22px;
            width: 22px;
          }
          .iconCustomStyle {
            font-size: 18px;
            color: $dark;
            @include sm {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  .rateProductButtonStyle {
    height: 40px;
    padding: 0;
    width: 200px;
    margin-top: 45px;
  }

  .comments {
    margin-top: 125px;
  }
}
