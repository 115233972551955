@import 'variables.scss';
.userMenu {
  background: $dark;
  color: $white;
  margin-top: 8px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 8px;
}
.userMenuItem {
  color: $white;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  margin-top: 2px;
  line-height: normal;
  letter-spacing: 0.25px;
  border-bottom: 1px solid #4f5063;
  text-transform: uppercase;
  &:hover {
    color: $dark;
  }
}
.userMenuItemLogout {
  color: $white;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  &:hover {
    color: $dark;
  }
}

