@import 'variables.scss';
.buttonUploadLogoStyle {
    border: solid 2px $dark;
    border-radius: 27.5px; 
    width: 140px;
    height: 50px;
    line-height: 0;
    font-weight: 500;
    letter-spacing: 0.35px;
    color: $white;
    text-transform: uppercase;
    background: $dark;
    font-size: 12px;
    &:focus {
      outline-style: none;
    }
  }