@import 'variables.scss';
.progressBarContainer {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: row;
  @include sm {
    flex-direction: column;
  }
  .progressBarTextContainer {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    @include sm {
      flex-direction: row;
      width: 50%;
      align-items: center;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
    }
    h5 {
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      @include sm {
        margin: 0px;
        padding-left: 10px;
      }
    }
    h3 {
      font-size: 14px;
      font-weight: bold;
      @include sm {
        margin: 0px;
      }
    }
  }
  .progressBarText {
    position: absolute;
    color: $dark;
    left: 20%;
    font-size: 16px;
    line-height: 2.25;
    text-align: center;
    z-index: 1;
    @include sm {
      display: none;
    }
  }
  .progressBarStyle {
    :global {
      .ant-progress-outer {
        .ant-progress-inner {
          border-radius: 0px;
          .ant-progress-bg {
            height: 55px !important;
            @include sm {
              height: 35px !important;
            }
          }
        }
      }
    }
  }
}
