@import 'variables.scss';
.contactUs {
  font-family: 'Poppins', sans-serif;
  .headlineWriteToUs {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: $dark;
    text-transform: uppercase;
    @include sm {
      display: none;
    }
  }
  .titleWriteToUs {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    color: $dark;
    @include sm {
      display: none;
    }
  }
  .mobileTitleWriteToUs {
    display: none;
    @include sm {
      font-size: 20px;
      font-weight: 500;
      color: $grey;
      text-transform: uppercase;
      display: block;
      padding-left: 15px;
    }
  }
  .inputsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
  @include sm {
    .inputsContainer {
      padding: 0px 15px;
    }
    img {
      margin-top: 0px;
    }
  }
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @include sm {
    padding-right: 5%;
    .buttonSuccessStyle {
      width: 50%;
      margin-left: auto;
    }
  }
}
.buttonSuccessStyle {
  width: 35%;
  line-height: 0px;
  height: 50px;
  margin-top: 15px;
  margin-bottom: 30px;
}
.textareaStyle {
  width: 100%;
  height: auto;
  .textAreaLabel {
    top: -6px;
    position: relative;
    width: 100px;
    margin: 0;
  }
  textarea {
    width: 100%;
    resize: none;
  }
  .error {
    text-align: right;
    position: absolute;
    top: -19px;
    margin: 0;
  }
}
