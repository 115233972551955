@import 'variables.scss';
.termsAndConditionsContainer {
  font-family: 'Poppins', sans-serif;
  padding-bottom: 40px;

  h2 {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 30px;
    padding-bottom: 33px;
  }
  h5 {
    font-size: 15px;
    font-weight: bold;
    padding-top: 15px;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    white-space: pre-line;
  }
}
