@import 'variables.scss';
.ourClientsContainer {
  font-family: 'Poppins', sans-serif;
  background-color: rgba(231, 233, 237, 0.45);
  h4 {
    font-size: 18px;
    font-weight: 500;
    padding-top: 27px;
    margin-bottom: 10px;
  }
  .inputSectionTitle {
    width: 100%;
    background: transparent;
    color: $dark;
    border-radius: 4px;
    margin-bottom: 47px;
    font-size: 30px;
    font-weight: 700;
  }
  .clientsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .clientItemContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    .addIconImgStyle {
      width: 140px;
      height: 140px;
      object-fit: cover;
      margin-top: 17px;
      border-radius: 50%;
    }
    .commentTagImageStyle {
      width: 40px;
      object-fit: contain;
    }
    .horizontalBreakline {
      border: 1px solid #191924;
      background: black;
      width: 25%;
    }
    .inputClientComment {
      width: 100%;
      background: transparent;
      border-radius: 4px;
      resize: none;
      font-size: 14px;
      font-style: italic;
      line-height: 1.5;
      letter-spacing: 0.66px;
      text-align: center;
      color: $dark;
      margin-bottom: 20px;
      min-height: 150px;
      @include tablets {
        line-height: normal;
        font-size: 11px;
      }
      @include sm {
        min-height: auto;
      }
    }
    .inputClientName {
      width: 100%;
      background: transparent;
      color: $dark;
      border-radius: 4px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.43;
      letter-spacing: 0.26px;
      padding-top: 10px;
    }
    .inputClientPosition {
      width: 100%;
      background: transparent;
      color: $dark;
      border-radius: 4px;
      margin-bottom: 20px;
      font-size: 12px;
      line-height: 1.67;
    }
    .textCounterContainer {
      width: 100%;
      margin-bottom: 0;
      text-align: right;
      font-size: 12px;
    }
  }
  .imageUploadButtonContainer {
    height: 70px;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
