@import 'variables.scss';
.feedCardContainer {
  font-family: 'Poppins', sans-serif;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 #f2f5f9;
  border: solid 1px #c1cbd7;
  .companyLogoContainer {
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 163px;
      width: 100%;
      object-fit: contain;
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    .customButtonStyles {
      width: 48%;
      padding: 0;
      height: 40px;
      font-size: 13px;
      text-align: center;
    }
  }
}
