@import "../../variables.scss";

.bannerContainer {
  width: 100%;
  background-color: $dark;

  .banner {
    width: 100%;
  }
}
