@import 'variables.scss';
.feedContainer {
  min-height: 100vh;
  position: relative;

  .emptySearchContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: absolute;
    top: 30%;
    left: 55%;
    transform: translate(0%, -50%);
    @include sm; @include tablets {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -30%);
      width: 100%;
    }
    img {
      width: 95px;
      object-fit: contain;
      align-self: center;
    }
    h6 {
      font-size: 36px;
      line-height: 1.03;
      letter-spacing: 0.13px;
      font-weight: normal;
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
      letter-spacing: 0.06px;
      margin-top: 1em;
    }
  }
  .jumpTopTop {
    width: 41px;
    object-fit: contain;
    cursor: pointer;
    position: fixed;
    right: 5%;
    bottom: 75px;
    z-index: 2;
  }
  .imageloaderStyle {
    margin-left: 50%;
    transform: translate(-50%, 0%);
    width: 120px;
  }
}
