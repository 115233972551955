@import 'variables.scss';
.viewModeSectionContainer {
  font-family: 'Poppins', sans-serif;
  h3 {
    font-size: 38px;
    font-weight: 600;
    letter-spacing: -0.5px;
    @include sm {
      font-size: 30px;
    }
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
    color: $steel-42;
  }
  .companyInfoSectionContianer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 30px;
    @include sm {
      justify-content: space-between;
    }
    h6 {
      font-size: 16px;
      text-align: center;
      font-weight: normal;
      color: $grey;
    }
    .profileRatingContianer {
      padding-right: 30px;
    }
    .employeeNumberContianer {
      text-align: center;
    }
  }
  p {
    font-size: 14px;
    line-height: 1.5;
  }
  .mainImageStyle {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .imagesContainer {
    display: flex;
    margin-bottom: 160px;
    .secondaryImagesStyles {
      width: 100%;
      height: 220px;
      object-fit: cover;
    }
  }
  .inputContainer {
      padding-bottom: 25px;
  }
}
