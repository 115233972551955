@import 'variables.scss';
.optionItem{
  display: flex;
  flex-direction: row;
  justify-content: center;
  img {
    width: 15px;
    object-fit: contain;
  }
  h5 {
    font-size: 11px;
    padding-top: 5px;
    padding-left: 5px;
    color: $white;
  }
}

.languageList{
    :global {
         .ant-list-items{
              .ant-list-item {
                 border-bottom: none;
                 padding-top: 0px;
                 padding-bottom: 0px;
                 padding-right: 0px;
                 padding-left: 12px;
             }
        } 
      }
      .languageListItem{
        &:hover {
         opacity: 0.5;
        }
      }
  }   
  
  .selectedLanguage{
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 12px;
  }

  .languageArrow{
    margin-right: 7px;
  }
 
    

