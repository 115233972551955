@import 'variables.scss';
.tabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  @include tablets {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @include sm {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 30px;
  }
}
.numberOfViews {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-left: 10px;
  color: $white;
}
.tabIcon {
  padding-right: 12px;
  padding-left: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include sm {
    padding: 0;
  }
  @include tablets {
    padding: 0;
  }
}
.tabText {
  color: $white;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: normal;
  font-size: 10px;
  &:hover {
    opacity: 0.5;
  }
  padding-bottom: 0;
  @include sm {
    padding-bottom: 25px;
    padding-top: 5px;
  }

  @include tablets {
    padding-bottom: 25px;
    padding-top: 5px;
  }
}

.shopIcon {
  font-size: 20px;
  color: white;
}
