@import 'variables.scss';
.navbarToggleButton {
    display: none;
    border: none;
    background: none;
    &:focus {
      outline: none;
    }
    @include tablets {
      height: 32px;
      width: 39px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 0px;
      z-index: 11;
      .line {
        height: 2px;
        width: 20px;
        background: white;
        transition: all 0.2s ease;
        &.lineTop {
          transform: none;
          transform-origin: top left;
          margin-bottom: 5px;
        }
        &.lineMiddle {
          opacity: 1;
          transform: none;
        }
        &.lineBottom {
          transform: none;
          transform-origin: top left;
          margin-top: 5px;
        }
      }
      &.navbarOpen {
        position: absolute;
        right: 45px;
        & .lineTop {
          transform: rotate(45deg);
        }
        & .lineMiddle {
          opacity: 0;
          transform: translateX(-16px);
        }
        & .lineBottom {
          transform: translateX(-1px) rotate(-45deg);
        }
      }
    }
    @include sm {
      height: 32px;
      width: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 20px;
      z-index: 11;
      .line {
        height: 2px;
        width: 20px;
        background: white;
        transition: all 0.2s ease;
        &.lineTop {
          transform: none;
          transform-origin: top left;
          margin-bottom: 5px;
        }
        &.lineMiddle {
          opacity: 1;
          transform: none;
        }
        &.lineBottom {
          transform: none;
          transform-origin: top left;
          margin-top: 5px;
        }
      }
      &.navbarOpen {
        position: relative;
        right: 0;
        & .lineTop {
          transform: rotate(45deg);
        }
        & .lineMiddle {
          opacity: 0;
          transform: translateX(-16px);
        }
        & .lineBottom {
          transform: translateX(-1px) rotate(-45deg);
        }
      }
    }
  }