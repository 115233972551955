@import 'variables.scss';
.sliderContainer {
  background-image: linear-gradient(to left, #6802f9, #6f94fd 0%);
  background: url('../../images/tutorial/background-bubbles.png') no-repeat;
  background-size: cover;
  font-family: 'Poppins', sans-serif;
  @include sm {
    background: #6919f9;
  }
  :global {
    height: 100%;
    ul {
      .slick-active div {
        height: 8px;
        width: 8px;
        background-color: $white;
        border-radius: 50%;
        opacity: 1;
      }
    }
    .custom-paging {
      height: 8px;
      width: 8px;
      background-color: $white;
      border-radius: 50%;
      opacity: 0.6;
    }
    .slick-dots {
      bottom: 140px;
      @include tablets {
        bottom: 17%;
      }
      @include sm {
        bottom: 28%;
      }
    }
    .slick-arrow {
      display: none !important;
    }
  }
  .slider {
    height: 100vh;
    white-space: pre-line;
    color: $white;
    text-align: center;
    h3 {
      color: $white;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
    }
    .textContainer {
      width: 25%;
      text-align: center;
      margin-bottom: 70px;
      @include tablets {
        width: 50%;
        padding-top: 50px;
        margin-bottom: 100px;
      }
      @include sm {
        width: 80%;
        padding-top: 35px;
        margin-bottom: 30px;
      }
    }
    .cancelButtonStyle {
      width: 170px;
      padding: 0px;
      height: 50px;
      background: transparent;
      border: 2px solid $white;
      border-radius: 6px;
      &:hover {
        border: 2px solid $dark;
      }
      @include sm {
        margin-top: 30px;
      }
    }
    .createPageButtonStyle {
      width: 170px;
      padding: 0px;
      height: 50px;
      background: $white;
      color: $dark;
      border-radius: 6px;
      &:hover {
        background: $dark;
        color: $white;
      }
      @include sm {
        background: $dark;
        color: $white;
        &:hover {
          background: $white;
          color: $dark;
        }
      }
    }
    .styleSelection {
      height: 90vh;
      display: flex !important;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      @include sm {
        margin-top: 20%;
        justify-content: flex-start;
      }
      img {
        height: 35%;
        align-self: center;
        @include sm {
          height: 25%;
          width: 90%;
          object-fit: contain;
        }
      }
    }
    .wayOfUsingSite {
      height: 90vh;
      display: flex !important;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      @include sm {
        margin-top: 20%;
        justify-content: flex-start;
      }
      img {
        height: 35%;
        align-self: center;
        @include sm {
          height: 25%;
        }
      }
    }
    .rateBizbook {
      height: 90vh;
      display: flex !important;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      @include sm {
        margin-top: 20%;
        justify-content: flex-start;
      }
      img {
        height: 35%;
        align-self: center;
        @include sm {
          height: 25%;
        }
      }
      .rateBizbookButtonContainer {
        display: flex;
        @include sm {
          flex-direction: column-reverse;
          button {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
