@import 'variables.scss';
.businessOpportunities {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 66px 0 170px;

  :global(.slick-arrow) {
    margin: 0 -25px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:global(.slick-prev) {
      margin: 0 -75px;
    }

    &:global(.slick-prev), &:global(.slick-next) {
      &:before {
        content: none;
      }
    }
  }

  .sliderCard {
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 $white;
    border: solid 1px #c1cbd7;
    background-color: $white;
    padding: 30px 15px;
    margin-bottom: 30px;

    .titleContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h1 {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.5;
        color: #323643;
        height: 54px;
        text-align: left;
        overflow: hidden;
        margin-right: 5px;
      }

      .companyImage {
        height: 45px;
        width: 45px;
        object-fit: cover;
        border-radius: 50%;
        background-color: #c1cbd7;
      }
    }

    .imgContainer {
      margin-bottom: 16px;
      height: 245px;

      .mainImage {
        width: 100%;
        height: 250px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 40px 55px -55px rgba(30, 130, 76, 0.54);
        object-fit: cover;
      }

      .views {
        background-color: $white;
        width: 110px;
        height: 40px;
        display: flex;
        padding-left: 12px;
        justify-content: flex-start;
        align-items: center;
        border-radius: 20px;
        box-shadow: 0 15px 55px 0 rgba(157, 163, 180, 0.2);
        position: relative;
        top: -52px;
        left: 12px;
        color: #323643;

        svg g {
          fill: #050713;
        }
        span {
          font-size: 15px;
          font-weight: 600;
          padding-left: 9px;
        }
      }
    }

    .offerInfo {
      h2 {
        font-size: 14px;
        color: #444;
        margin-bottom: 3px;
      }
      span {
        font-size: 13px;
        opacity: 0.54;
        color: #323643;
        line-height: 1.54;
      }
    }

    .additionalImages {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      img {
        width: 30%;
        border-radius: 6px;
        background-color: #c1cbd7;
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #e1e3e8;
      opacity: 0.6;
      border-radius: 0.5px;
      margin: 15px 0;
    }

    .tagsContainer {
      height: 50px;
      display: flex;
      flex-direction: row;
      margin-top: 25px;
      padding-bottom: 25px;
      overflow: hidden;
      width: 100%;

      &:hover {
        overflow-x: scroll;
      }

      .tag {
        margin-right: 5px;
        padding: 10px 15px;
        max-width: 185px;
        height: 25px;
        border-radius: 15px;
        border: solid 0.5px rgba(157, 163, 180, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;

        &:last-child {
          margin-right: 0;
        }

        span {
          font-size: 13px;
          text-align: center;
          color: #323643;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
    }

    .moreButton {
      width: 100%;
      height: 50px;
      border-radius: 6px;
      border: none;
      background-blend-mode: soft-light, normal;
      background-image: linear-gradient(280deg, #000, $white 0%),
      linear-gradient(to bottom, #050713, #050713);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      font-size: 13px;
      letter-spacing: 0.3px;
      text-align: center;
      color: $white;
      text-transform: uppercase;
    }
  }

  @include tablets {
    margin: 66px 0 100px;
  }

  @media (max-width: 374px) {
    .imgContainer {
      height: 200px!important;
      .mainImage {
        height: 200px!important;
      }
    }
  }

  @media (min-width: 390px) and (max-width: 767px) {
    :global(.slick-initialized) {
      width: 350px;
      margin: 0 calc((100% - 350px) / 2);
    }
  }

  @media (min-width: 768px) {
    .sliderCard {
      margin: 0 10px 30px;

      .imgContainer {
        .mainImage {
          height: 226px;
        }
      }
    }

    .slider1 {
      :global(.slick-initialized) {
        width: 350px;
        margin: 0 380px;
      }
    }
  }

  @media (min-width: 992px) {
    :global(.slick-initialized) {
      width: 760px;
      margin: 0 85px;
    }
  }

  @media (min-width: 1200px) {
    :global(.slick-initialized) {
      width: 100%;
      margin: 0;
    }

    .slider2 {
      :global(.slick-initialized) {
        width: 760px;
        margin: 0 155px;
      }
    }
  }

  .slider1,
  .slider2 {
    width: auto;
  }
}
