@import "variables.scss";

.commentPreview {
  display: flex;
  margin-top: 30px;
  border-bottom: 1px solid $steel-42;
  font-family: Arial;
  max-width: 741px;
  width: 100%;
  padding-bottom: 32px;
  position: relative;

  img {
    height: 100px;
    object-fit: contain;
    border-radius: 50%;
    background-size: cover;
  }

  .commentPreviewContent {
    margin-left: 33px;

    .commentPrewiewCompany {
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 24px;
      line-height: 24px;
      color: #222222;
    }

    .commentPreviewDate {
      margin-top: 11px;
      margin-bottom: 0;
      font-size: 17px;
      line-height: 17px;
      color: #222222;
    }

    .commentPreviewText {
      margin-top: 15px;
      margin-bottom: 0;
      font-size: 17px;
      color: #222222;
      word-break: break-word;
    }
  }

  .actions {
    display: flex;
    position: absolute;
    top: 12px;
    right: 0;
    @include sm {
      top: auto;
      bottom: 0;
    }
    .actionButton {
      font-family: "Poppins", sans-serif;
      font-size: 13px;
      text-align: center;
      border: none;
      outline: none;
      background-color: transparent;
      letter-spacing: 1.06px;
      text-transform: uppercase;
    }
    .actionButtonRed {
      color: #ff0000;
    }
  }
}
